import { ApiActionTypes } from "../actionTypes";

const initialState = {
  loading: false,
  counter: 0,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ApiActionTypes.REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case ApiActionTypes.SUCCESS:
    case ApiActionTypes.FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }
    // TESTING
    case "TEST": {
      return {
        ...state,
        counter: state.counter + 1,
      };
    }
    default:
      return state;
  }
};
