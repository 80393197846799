import React, { useEffect, useState } from "react";
import { get } from "lodash";

import LandingHeader from "../../components/landing_page/landing_header";
import LandingContent from "../../components/landing_page/landing_content";
import LandingImagesMain from "../../components/landing_page/landing_image_main";
import LandingCandidate from "../../components/landing_page/landing_candidate";
import LandingHiring from "../../components/landing_page/landing_hiring";
import LandingFooter from "../../components/landing_page/landing_footer";
import { useHistory } from "react-router-dom";
/**
 *  Default component for landing page which is used to display images to the user before he sign in/ join in
 */
function Default_Landing(props) {
  const history = useHistory();
  useEffect(() => {
    const cpacktoken = localStorage.getItem("cpacktoken");
    const lpacktoken = localStorage.getItem("lpacktoken");
    const admintoken = localStorage.getItem("admintoken");
    if (cpacktoken || lpacktoken) {
      if (cpacktoken) {
        history.push(`/cpack/${cpacktoken}`);
      }
      if (lpacktoken) {
        history.push(`/lpack/profile/${lpacktoken}`);
      }
      if (admintoken) {
        history.push(`/superadmin/${admintoken}`);
      }
    }
  }, []);
  return (
    <>
      <LandingHeader
        recoveryToken={get(props, "location.state.recoveryToken", "")}
      />
      {/* <LandingImagesMain />
      <LandingCandidate />
      <LandingHiring /> */}
      <LandingFooter />
    </>
  );
}

export default Default_Landing;
