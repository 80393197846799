import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import SvgTradeWorksIconSmall from "../data_display/icons/TradeWorksIconSmall";
import SvgFrStamp from "../data_display/icons/FrStamp";
import CustomButton from "../navigations/custom_buttons";
import { color, font_sizes, pxToRem } from "../../utilities/themes";
import strings from "../../utilities/strings";
import CustomLink from "../navigations/custom_links";
import LoginModal from "../../pages/login/login";
import Join_Us from "../../pages/auth/joinus/join_us";
import FindAccount from "../../pages/password/find_account";
import NewPassword from "../../pages/password/new_password";
import ResetPassword from "../../pages/password/reset_password";
import EnterOtp from "../../pages/password/security";
import PasswordChangeSuccess from "../../pages/password/success";
import EmailNotifySuccess from "../../pages/password/email_notify_success";
import SuccessPopupContent from "../../components/Modals/success_modal/success_modal";
import RecoverHere from "../../pages/password/recover_here";
import FindEmail from "../../pages/password/find_email";
import LandingContent from "./landing_content";

/**
 * Header component for landing page which is displayed to the user before he sign in/ join in
 */

const useStyles = {
  headerGridStyle: {
    borderBottom: `1px solid ${color.primary_palette.border_opacity}`,
    height: "4.25rem",
  },
  joinUsBtnStyle: {
    width: "6.8125rem",
    height: "1.875rem",
    borderRadius: "9.8px",
    border: `2px solid ${color.primary_palette.highlight_purple}`,
    "&:hover": {
      border: `2px solid ${color.user_green}`,
      color: color.user_green,
    },
  },
  joinUsBtnTxtStyle: {
    fontSize: font_sizes.font_18,
    fontWeight: "600",
    lineHeight: "normal",
    color: color.primary_palette.franklin_purple,
    "&:hover": {
      color: color.user_green,
    },
  },
  loginTxtStyle: {
    fontSize: font_sizes.font_18,
    color: color.primary_palette.black,
    cursor: "pointer",
  },
  iconAlign: {
    marginLeft: "0.9375rem",
  },
  cursorPointer: {
    cursor: "pointer",
  },
  iconHighlight: {
    border: `3px solid ${color.primary_palette.highlight_purple}`,
    padding: pxToRem(2),
    borderRadius: pxToRem(10),
  },
};

class LandingHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displayLogin: localStorage.getItem("SloginType") === "login",
      displayJoinUs: localStorage.getItem("SloginType") === "joinus",
      signUpError: false,
      showFindAccount: false,
      showFindEmail: false,
      showRecoverHere: false,
      showResetPassword: false,
      showOtpModal: false,
      showNewPassword: false,
      pwdSuccess: false,
      emailNotifySuccess: false,
      errorMessage: "",
    };
  }

  componentDidMount = () => {
    if (this.props.recoveryToken) {
      this.toggleModal("displayLogin", "showNewPassword");
    }
  };
  handle_navigate_home = () => {
    this.props.history.push("/");
  };

  toggleLoginModal = () => {
    localStorage.removeItem("SloginType");
    this.setState((prevState, prevProps) => {
      return { displayLogin: !prevState.displayLogin, displayJoinUs: false };
    });
  };

  toggleJoinUsModal = () => {
    localStorage.removeItem("SloginType");
    this.setState({
      displayJoinUs: true,
      displayLogin: false,
      signUpError: false,
      errorMessage: "",
      emailId: null,
    });
  };

  toggleModal = (closeModal, openModal) => {
    localStorage.removeItem("SloginType");
    if (openModal) {
      this.setState({
        [closeModal]: false,
        [openModal]: true,
      });
      return;
    }
    this.setState({
      [closeModal]: false,
    });
  };

  signUpError = (errorMessage, emailId) => {
    this.setState({
      signUpError: true,
      errorMessage: errorMessage,
      emailId: emailId,
    });
  };

  redirectToFranklin = () => {
    window.location.href = "https://www.franklinreport.com/";
  };

  clearError = () => {
    this.setState({ signUpError: false, errorMessage: "", emailId: "" });
  };

  displayWelcome = () => {
    this.setState({ displayWelcome: true, displayJoinUs: false });
  };

  closeJoinUs = () => {
    this.setState({ displayJoinUs: false });
  };
  render() {
    const {
      displayLogin,
      displayJoinUs,
      signUpError,
      errorMessage,
      emailId,
      displayWelcome,
      showFindAccount,
      showFindEmail,
      showRecoverHere,
      showResetPassword,
      showOtpModal,
      showNewPassword,
      pwdSuccess,
      emailNotifySuccess,
    } = this.state;
    const { classes } = this.props;
    return (
      <>
        <Grid container>
          <Grid item xs={5}>
            <Grid
              container
              item
              xs
              className={classes.headerGridStyle}
              alignItems="center"
            >
              <Grid
                item
                container
                xs={2}
                md={1}
                justify="center"
                className={`${classes.iconAlign} ${classes.iconHighlight}`}
              >
                <SvgTradeWorksIconSmall className={classes.icon1} />
              </Grid>
              {/* <Grid
                item
                container
                xs={2}
                md={1}
                justify="center"
                className={`${classes.iconAlign}`}
              >
                <img
                  src="assets/images/icon_hat.png"
                  alt="icon hat"
                  className={classes.icon2}
                />
              </Grid>
              <Grid
                item
                container
                xs={2}
                md={1}
                justify="center"
                className={`${classes.iconAlign}`}
              >
                <img
                  src="assets/images/flipbook_icon.PNG"
                  alt="flipbook"
                  className={classes.icon3}
                />
              </Grid> */}
              <Grid
                item
                container
                xs={2}
                md={2}
                className={`${classes.iconAlign}`}
              >
                <SvgFrStamp
                  className={`${classes.icon4} ${classes.cursorPointer}`}
                  onClick={this.redirectToFranklin}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={2}>
            <Grid
              container
              item
              xs
              justify="center"
              alignItems="center"
              onClick={this.handle_navigate_home}
              // className={classes.cursorPointer}
            >
              <img src="assets/images/trade-works.png" alt="Trade Works" />
            </Grid>
          </Grid>
          <Grid item xs={5}>
            <Grid
              container
              item
              xs
              justify="flex-end"
              alignItems="center"
              className={classes.headerGridStyle}
            >
              <Grid
                item
                xs={3}
                sm={4}
                md={3}
                style={{ textAlign: "right", marginRight: "14px" }}
              >
                {/* <CustomLink color="#000" onClick={this.toggleLoginModal}>
                  <label className={classes.loginTxtStyle}>
                    {strings.landing_page.titles.log_in}
                  </label>
                </CustomLink> */}
                <CustomButton
                  variant="outlined"
                  color="primary"
                  className={classes.joinUsBtnStyle}
                  onClick={this.toggleLoginModal}
                >
                  <span className={classes.joinUsBtnTxtStyle}>
                    {strings.landing_page.titles.log_in}
                  </span>
                </CustomButton>
              </Grid>
              <Grid item xs={3} sm={4} md={3}>
                <CustomButton
                  variant="outlined"
                  color="primary"
                  className={classes.joinUsBtnStyle}
                  onClick={this.toggleJoinUsModal}
                >
                  <span className={classes.joinUsBtnTxtStyle}>
                    {strings.landing_page.titles.join_us}
                  </span>
                </CustomButton>
              </Grid>
            </Grid>
          </Grid>
          <React.Fragment>
            {displayLogin && (
              <Grid container item justify="center" alignItems="center">
                <LoginModal
                  closeModal={this.toggleLoginModal}
                  openJoinusModal={this.toggleJoinUsModal}
                  signUpError={signUpError}
                  errorMessage={errorMessage}
                  emailId={emailId}
                  toggleModal={this.toggleModal}
                  clearError={this.clearError}
                />
              </Grid>
            )}
            {displayJoinUs && (
              <Grid container item justify="center" alignItems="center">
                <Join_Us
                  closeModalJoin={this.toggleJoinUsModal}
                  openLoginModal={this.toggleLoginModal}
                  signUpError={this.signUpError}
                  displayWelcome={this.displayWelcome}
                  displayJoinUs={displayJoinUs}
                  closeJoinUs={this.closeJoinUs}
                />
              </Grid>
            )}
            {displayWelcome && (
              <Grid container item justify="center" alignItems="center">
                <SuccessPopupContent open={true} />{" "}
              </Grid>
            )}
            <Grid container item justify="center" alignItems="center">
              <FindAccount
                key={showFindAccount}
                showFindAccount={showFindAccount}
                toggleModal={this.toggleModal}
              />
            </Grid>
            <Grid container item justify="center" alignItems="center">
              <FindEmail
                key={showFindEmail}
                showFindEmail={showFindEmail}
                toggleModal={this.toggleModal}
              />
            </Grid>
            <Grid container item justify="center" alignItems="center">
              <ResetPassword
                key={showResetPassword}
                showResetPassword={showResetPassword}
                toggleModal={this.toggleModal}
              />
            </Grid>
            <Grid container item justify="center" alignItems="center">
              <EnterOtp
                key={showOtpModal}
                showOtpModal={showOtpModal}
                toggleModal={this.toggleModal}
              />
            </Grid>
            <Grid container item justify="center" alignItems="center">
              <NewPassword
                key={showNewPassword}
                showNewPassword={showNewPassword}
                toggleModal={this.toggleModal}
                token={this.props.recoveryToken}
              />
            </Grid>
            <Grid container item justify="center" alignItems="center">
              <PasswordChangeSuccess
                key={pwdSuccess}
                pwdSuccess={pwdSuccess}
                toggleModal={this.toggleModal}
              />
            </Grid>
            <Grid container item justify="center" alignItems="center">
              <EmailNotifySuccess
                key={emailNotifySuccess}
                emailNotifySuccess={emailNotifySuccess}
                toggleModal={this.toggleModal}
              />
            </Grid>
            <Grid container item justify="center" alignItems="center">
              <RecoverHere
                key={showRecoverHere}
                showRecoverHere={showRecoverHere}
                openLoginModal={this.toggleLoginModal}
                toggleModal={this.toggleModal}
              />
            </Grid>
          </React.Fragment>
        </Grid>
        <LandingContent openLoginModal={this.toggleLoginModal} />
      </>
    );
  }
}

export default withRouter(
  withStyles(useStyles, { withTheme: true })(LandingHeader)
);
