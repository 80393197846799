import React, { useRef, useEffect, useState } from "react";
import { get } from "lodash";

import { color } from "../../utilities/themes";
import newPasswordStyles from "./styles/newPasswordStyles";
import Text from "../../components/common/ui_kit/text";
import Row from "../../components/common/ui_kit/row";
import CustomTextField from "../../components/inputs/custom_textfields";
import strings from "../../utilities/strings";
import CustomLink from "../../components/navigations/custom_links";
import CustomModal from "../../components/inputs/custom_modal";

function SecurityModal(props) {
  const { classes, showOtpModal, toggleModal } = props;
  const [values, setValues] = useState({});
  const references = {
    inp1: useRef(null),
    inp2: useRef(null),
    inp3: useRef(null),
    inp4: useRef(null),
    inp5: useRef(null),
    inp6: useRef(null),
  };

  useEffect(() => {
    if (references.inp1 && references.inp1.current) {
      references.inp1.current.focus();
    }
  }, []);

  const onChangeInput = (e) => {
    const { name, value, id } = e.target;
    setValues({ ...values, [name]: value });
    if (id < 6) {
      references[`inp${Number(id) + 1}`].current.focus();
    }
    if (id == 6) {
      const { input1, input2, input3, input4, input5, input6 } = values;
      const otp = `${input1}${input2}${input3}${input4}${input5}${value}`;
      validateOtp(otp);
    }
  };

  const validateOtp = (otp) => {
    if (otp === "434343") {
      toggleModal("showOtpModal", "showNewPassword");
    } else {
      setValues({
        ...values,
        input1: "",
        input2: "",
        input3: "",
        input4: "",
        input5: "",
        input6: "",
        otpError: true,
      });
      references.inp1.current.focus();
    }
  };

  return (
    <>
      <CustomModal
        open={showOtpModal}
        aria-labelledby="modal-title"
        disablePortal
        aria-describedby="modal-description"
        disableAutoFocus
      >
        <div className={classes.securityModal_width}>
          <div className={classes.textCenter}>
            <img src="/assets/images/lock.png" className={classes.lock_img} />
          </div>
          <Text
            size={24}
            color={color.primary_palette.black}
            family="gillsans_sb"
            transform="uppercase"
            className={classes.textCenter}
          >
            {strings.passwords.titles.security_code}
          </Text>
          <Text
            size={16}
            color={color.primary_palette.franklin_purple}
            family="gillsans_sb"
            className={`${classes.textCenter} ${classes.text_message_spacing}`}
          >
            {strings.passwords.titles.text_message}
          </Text>
          <Row className={classes.justify_evenly}>
            <div>
              <input
                className={classes.security_block}
                ref={references.inp1}
                name="input1"
                id={1}
                onChange={onChangeInput}
                value={get(values, "input1", "")}
              />
            </div>
            <div>
              <input
                className={classes.security_block}
                ref={references.inp2}
                name="input2"
                id={2}
                onChange={onChangeInput}
                value={get(values, "input2", "")}
              />
            </div>
            <div>
              <input
                className={classes.security_block}
                ref={references.inp3}
                name="input3"
                value={get(values, "input3", "")}
                id={3}
                onChange={onChangeInput}
              />
            </div>
            <div>
              <input
                className={classes.security_block}
                ref={references.inp4}
                name="input4"
                id={4}
                value={get(values, "input4", "")}
                onChange={onChangeInput}
              />
            </div>
            <div>
              <input
                className={classes.security_block}
                ref={references.inp5}
                name="input5"
                value={get(values, "input5", "")}
                id={5}
                onChange={onChangeInput}
              />
            </div>
            <div>
              <input
                className={classes.security_block}
                ref={references.inp6}
                name="input6"
                id={6}
                value={get(values, "input6", "")}
                onChange={onChangeInput}
              />
            </div>
          </Row>
          {get(values, "otpError", false) && (
            <div>
              <Text
                size={16}
                family="gillsans_sb"
                className={`${classes.textCenter} ${classes.text_message_spacing}`}
              >
                {strings.passwords.titles.invalid_otp}
              </Text>
            </div>
          )}
          <div className={`${classes.textCenter} ${classes.get_code_spacing}`}>
            <CustomLink underline="always" className={`${classes.recover} `}>
              <label className={classes.cursor}>
                {strings.passwords.titles.get_code}
              </label>
            </CustomLink>
          </div>
        </div>
      </CustomModal>
    </>
  );
}

export default newPasswordStyles(SecurityModal);
