import { withStyles } from "@material-ui/core/styles";
import tradework_theme, { color, pxToRem } from "../../../utilities/themes";

const styles = withStyles({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  feedbackDiv:{
    top: '172px',
    right: '30px',
    zIndex: 9,
    position: 'fixed'
  },
  Interest_width: {
    width: pxToRem(350),
    border: `solid ${pxToRem(1.5)} ${color.primary_palette.franklin_purple}`,
    background: "#f8f0a7",
    margin: "0 auto",
    padding: "39px 20px 0px 20px",
    position: "relative",
    outline: "none",
    height: pxToRem(275)
  },
  header_text: {
    fontSize: pxToRem(25),
    textAlign: "center",
    ...tradework_theme.typography.styles.gillsans_light,
    textTransform: "uppercase",
    marginBottom: pxToRem(10),
    color: color.secondary_palette.grays.text_gray
  },
  step_des: {
    fontSize: pxToRem(10),
    ...tradework_theme.typography.styles.gillsans_sb,
    color: color.primary_palette.franklin_purple
  },
  choose_format: {
    fontSize: pxToRem(16),
    ...tradework_theme.typography.styles.gillsans_sb,
    color: color.primary_palette.franklin_purple,
    textTransform: "uppercase"
  },
  radioTxt: {
    textAlign: "center",
    "& .MuiTypography-root": {
      fontSize: pxToRem(14)
    }
  },
  bullet_point_text: {
    border: `solid ${pxToRem(1)} ${color.secondary_palette.grays.shadow_gray}`,
    display: "inline-block",
    verticalAlign: "middle"
  },
  bullet_point_align: {
    padding: "6px 8px 10px 21px",
    margin: 0,
    "& li": {
      fontSize: pxToRem(16)
    }
  },
  paragraph: {
    border: `solid ${pxToRem(1)} ${color.secondary_palette.grays.shadow_gray}`,
    display: "inline-block",
    verticalAlign: "middle",
    width: pxToRem(256),
    padding: "8px 7px 8px 8px",
    marginLeft: pxToRem(15)
  },
  radio_spacing: {
    marginTop: pxToRem(5)
  },
  icon: {
    border: `solid ${pxToRem(1)} ${color.secondary_palette.grays.shadow_gray}`,
    display: "inline-block",
    verticalAlign: "middle",
    width: pxToRem(332),
    padding: "8px 7px 8px 8px"
  },
  textAlign: {
    textAlign: "right"
  },
  closeBtn: {
    "& :hover":{
      color:"#000"
    },
    paddingRight: 0,
    paddingLeft: 0,
    minWidth: pxToRem(18),
    position: "absolute",
    left: "10px",
    marginTop: "-41px"
  },
  joinUs_cross: {
    fontSize: pxToRem(15)
  },
  step_1: {
    borderBottom: `solid ${pxToRem(1.5)} ${
      color.primary_palette.franklin_purple
    }`,
    paddingBottom: pxToRem(35)
  },
  compass_size: {
    fontSize: pxToRem(19),
    color: color.primary_palette.franklin_purple,
    "& path": {
      "&:nth-child(1)": {
        fill: color.primary_palette.franklin_purple
      }
    }
  },
  step_1_box: {
    border: `solid ${pxToRem(1)} ${color.primary_palette.franklin_purple}`,
    padding: "7px 17px",
    marginTop: pxToRem(6),
    position: "relative"
  },
  root: {
    width: pxToRem(258),
    "& .MuiFormControl-fullWidth": {
      margin: 0
    },
    "& .MuiSvgIcon-root": {
      fontSize: pxToRem(20),
      paddingRight: pxToRem(10),
      paddingLeft: pxToRem(10)
    }
  },
  blk_text: {
    ...tradework_theme.typography.styles.gillsans_sb,
    fontSize: pxToRem(14),
    paddingBottom: pxToRem(5),
    "& .MuiSvgIcon-root": {
      fontSize: pxToRem(20)
    }
  },
  required_icon: {
    color: color.form_colors.blueberry_purple,
    fontSize: pxToRem(14) + ` !important`,
    marginLeft: pxToRem(6)
  },
  error_icon: {
    color: color.primary_palette.christmas_red,
    fontSize: pxToRem(14) + ` !important`,
    marginLeft: pxToRem(6)
  },
  plain_input: {
    margin: 0,
    width: "100%",
    "& .MuiInputBase-root": {
      width: "100%",
      fontSize: pxToRem(16),
      "& .MuiInputBase-input": {
        border: '1px',
        background:"#fff"
      }
    }
  },
  from_input: {
    width: "85%",
    "& .MuiInputBase-root": {
      width: "100%",
      marginTop:"11px",
      "& .MuiInputBase-input": {
        background:"none",
        paddingBottom: '1px'
      }
    }
  },
  emailField:{
    width:"100px",
    paddingTop:"12px"
  },
  field_des: {
    fontSize: pxToRem(12),
    color: color.secondary_palette.grays.light_gray,
    ...tradework_theme.typography.styles.gillsans_sb
  },
  saveTitleStyle: {
    textAlign: "right",
    cursor: "pointer",
    paddingTop: pxToRem(5)
  },
  saveImg: {
    paddingRight: pxToRem(5),
    width:'14px'
  },
  cursorPointer: {
    cursor: "pointer",
    fontSize: pxToRem(14),
    "&:hover": {
      color: color.primary_palette.franklin_purple + ` !important`
    }
  },
  floatRight: {
    float: "right",
    "& .MuiSvgIcon-root": {
      position: "absolute"
    },
    "&:hover": {
      color: "white !important"
    }
  },
  buttonDiv: {
    width: "100%",
    marginTop: pxToRem(16),
    marginBottom: pxToRem(16),
    "& .MuiButtonBase-root": {
      paddingTop: 0,
      paddingBottom: 0,
      width: pxToRem(113),
      fontSize: pxToRem(15),
      ...tradework_theme.typography.styles.gillsans_sb
    },
    "& .MuiButton-outlined.Mui-disabled": {
      color: color.primary_palette.franklin_purple,
      opacity: 0.3,
      border: `solid ${pxToRem(1)} ${color.form_colors.blueberry_purple}`
    }
  },
  plus_font: {
    fontSize: pxToRem(12)
  },
  step_spacing: {
    paddingTop: pxToRem(15),
    paddingBottom: pxToRem(5)
  },
  spacing_block: {
    padding: "0.3125em 0"
  },
  school_logo: {
    position: "absolute",
    right: 0,
    top: 0,
    padding: "0 20px"
  },
  update_Icon: {
    padding: "0.1875em 0",
    ...tradework_theme.typography.styles.gillsans_sb,
    fontSize: pxToRem(13)
  },
  imageSize_logo: {
    height: "74px",
    width: "74px",
    padding: "2px"
  },
  insideInput: {
    display: "none"
  },
  beta_pos: {
    height: pxToRem(70),
    width: pxToRem(70),
    margin: "-67px auto 0 auto",
    position: 'absolute',
    right: '10px'
  },
  textArea: {
    width: "100%",
    height: "108px !important",
    resize: "none",
    outline: "none",
    fontFamily: "NeutraText",
    marginTop: "5px",
    paddingRight: pxToRem(15),
    fontSize: pxToRem(16),
    paddingLeft: pxToRem(11)
  },
  buttonAlign: {
    marginTop: pxToRem(12),
    width: "96px !important",
    fontWeight:"bold",
    color:'#c52d33',
    cursor:'pointer',
    paddingTop: '9px',
    textAlign: 'right'
  },
  textFieldAlign: {
    marginTop: pxToRem(8),
    color: '#000',
    fontWeight: "bold"
  },
  error_textFieldAlign: {
    marginTop: pxToRem(20),
    color: color.primary_palette.christmas_red
  },
  headingAlign: {
    marginTop: pxToRem(26)
  },
  pt12:{
    paddingTop: "12px"
  }
});

export default styles;
