import React from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { withStyles } from "@material-ui/core/styles";
import { TextField, CircularProgress } from "@material-ui/core";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import { color } from "../../utilities/themes";
import { makeStyles } from "@material-ui/styles";
import { get } from "lodash";

/**
 * Auto Suggest Component with pre populated options on text input
 * @param {*} props
 */

const useStyles = makeStyles({
  MuiAutocomplete: {
    "& input:first-child": {
      marginLeft: "40px",
    },
    "&:after": {
      borderBottom: "transparent !important",
    },
    "&:before": {
      borderBottom: "transparent !important",
    },
    "& .MuiFormControl-root": {
      "&:after": {
        borderBottom: "transparent !important",
      },
      "&:before": {
        borderBottom: "transparent !important",
      },
      "&:hover": {
        border: "1px solid #C6DDFF !important",
        "&:not(.Mui-disabled)": {
          "&:after": {
            borderBottom: "transparent !important",
          },
          "&:before": {
            borderBottom: "transparent !important",
          },
        },
      },
      "&:active": {
        border: "1px solid #5E94E0 !important",
        "&:not(.Mui-disabled)": {
          "&:after": {
            borderBottom: "transparent !important",
          },
          "&:before": {
            borderBottom: "transparent !important",
          },
        },
      },
      "&:focus": {
        border: "1px solid #5E94E0 !important",
        "&:not(.Mui-disabled)": {
          "&:after": {
            borderBottom: "transparent !important",
          },
          "&:before": {
            borderBottom: "transparent !important",
          },
        },
      },
      "&:focus-within": {
        border: "1px solid #5E94E0 !important",
        "&:not(.Mui-disabled)": {
          "&:after": {
            borderBottom: "transparent !important",
          },
          "&:before": {
            borderBottom: "transparent !important",
          },
        },
      },
    },
  },
  error: {
    "& .MuiFormControl-root": {
      border: `2px solid ${color.primary_palette.tricks_red} !important`,
      margin: 0,
    },
  },
  text_info: {
    display: "inline-block",
    verticalAlign: "middle",
  },
  text_sub: {
    display: "block",
    fontSize: "17px",
  },
});

const CustomAutocomplete = (props) => {
  const classes = useStyles();
  return (
    <>
      <Autocomplete
        {...props}
        className={`${props.className} ${
          props.hasLogo || props.professionalSummary || props.hasCompanyLogo
            ? classes.MuiAutocomplete
            : ""
        } ${props.error && classes.error}`}
        style={{
          ...(props.error && {
            borderColor: color.primary_palette.christmas_red,
          }),
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            margin="normal"
            fullWidth
            placeholder={props.placeholder}
            onChange={props.onTextChange}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {props.loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {props.endAdornment || params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
            onFocus={(e) => {
              e.target.placeholder = "";
              props.onFocus && props.onFocus(e);
            }}
            onBlur={(e) => {
              e.target.placeholder = props.placeholder || "";
              props.onBlur && props.onBlur(e);
            }}
          />
        )}
        renderOption={(option, { inputValue }) => {
          const matches = match(option.name, inputValue);
          const parts = parse(option.name, matches);
          return (
            <div>
              {props.hasLogo && (
                <img
                  style={{
                    height: "13px",
                    width: "13px",
                    margin: "0px 5px 0px 0px",
                  }}
                  src={
                    option.logo
                      ? option.logo
                      : "assets/images/blank-school-icon.svg"
                  }
                />
              )}
              {props.hasCompanyLogo && (
                <img
                  style={{
                    height: "13px",
                    width: "13px",
                    margin: "0px 5px 0px 0px",
                  }}
                  src="https://trablobdev.blob.core.windows.net/tra-con-use-dev/eagles/0.jpg"
                  // src={get(
                  //   option,
                  //   "companyLogo",
                  //   `https://trablobdev.blob.core.windows.net/tra-con-use-dev/eagles/${get(
                  //     option,
                  //     "FRCompanyId",
                  //     "0"
                  //   )}.jpg`
                  // )}
                />
              )}
              {props.professionalSummary ? (
                <div>
                  <img
                    style={{
                      height: "36px",
                      width: "36px",
                      verticalAlign: "middle",
                      padding: "10px 15px 10px 5px",
                    }}
                    className={classes.logo_width}
                    src={
                      option.logo
                        ? option.logo
                        : "assets/images/blank-school-icon.svg"
                    }
                    // src="assets/images/blank-school-icon.svg"
                  />
                  <div className={classes.text_info}>
                    <span className={classes.text_sub}>{option.name}</span>
                    <span>{option.institutionName}</span>

                    {props.recomendation && <span>{option.primaryTrade}</span>}
                  </div>
                </div>
              ) : (
                parts.map((part, index) => (
                  <span
                    key={index}
                    style={{
                      color: part.highlight
                        ? props.highlightColor
                          ? props.highlightColor
                          : "#000"
                        : "#000",
                      fontWeight: part.highlight ? 700 : 400,
                    }}
                  >
                    {part.text}
                  </span>
                ))
              )}
            </div>
          );
        }}
      />
      {/* {props.error && (
        <p style={{ color: color.primary_palette.christmas_red, margin: 0 }}>
          {props.error}
        </p>
      )} */}
    </>
  );
};

export default CustomAutocomplete;
