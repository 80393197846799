import _ from "lodash";
import { LoginApi } from "../api";
import { LoginTypes } from "../actionTypes";
import { ApiActions } from ".";

export default {
  loginUserSuccess: (dispatch, user) => {
    dispatch({
      type: LoginTypes.LOGIN_USER_SUCCESS,
      payload: user,
    });
  },
  login: (body, cb) => {
    return async (dispatch) => {
      ApiActions.request(dispatch);
      let res = null;
      try {
        res = await LoginApi.login(body);
        if (!_.get(res, "data.error.error")) {
          dispatch({
            type: LoginTypes.LOGIN_USER_SUCCESS,
            data: _.get(res, "data.data", {}),
          });
          cb && cb(_.get(res, "data.data", {}));
        } else {
          dispatch({
            type: LoginTypes.LOGIN_USER_FAIL,
            payload: res.data,
          });
        }
        ApiActions.success(dispatch);
      } catch (err) {
        ApiActions.failure(dispatch, err);
      }
    };
  },
  forgotPassword: (data, cb) => {
    return async (dispatch) => {
      ApiActions.request(dispatch);
      try {
        const res = await LoginApi.forgotPassword(data);
        cb && cb(res.data);
        ApiActions.success(dispatch);
      } catch (err) {
        ApiActions.failure(dispatch, err);
      }
    };
  },
  sendFeedback: (data, cb) => {
    return async (dispatch) => {
      ApiActions.request(dispatch);
      try {
        const res = await LoginApi.postFeedback(data);
        cb && cb(res.data);
        ApiActions.success(dispatch);
      } catch (err) {
        ApiActions.failure(dispatch, err);
      }
    };
  },
  tradeLocations: (data, cb) => {
    return async (dispatch) => {
      ApiActions.request(dispatch);
      try {
        const res = await LoginApi.tradeLocations(data);
        cb && cb(res.data);
        dispatch({
          type: LoginTypes.TRADE_LOCATIONS,
          data: _.get(res, "data.data", {}),
        });
        ApiActions.success(dispatch);
      } catch (err) {
        ApiActions.failure(dispatch, err);
      }
    };
  },
  setNewPassword: (data, cb) => {
    return async (dispatch) => {
      ApiActions.request(dispatch);
      try {
        const res = await LoginApi.setNewPassword(data);
        cb && cb(res);
        ApiActions.success(dispatch);
      } catch (err) {
        ApiActions.failure(dispatch, err);
      }
    };
  },
  logOut: () => {
    return {
      type: LoginTypes.LOGOUT,
    };
  },
  clearLogInProps: () => {
    return {
      type: LoginTypes.CLEAR_LOGIN_PROPS,
    };
  },
  toggleLoginModal: (show) => {
    return {
      type: LoginTypes.TOGGLE_LOGIN_MODAL,
      payload: show,
    };
  },
};
