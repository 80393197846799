import React, { useState } from "react";
import { get } from "lodash";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { color } from "../../utilities/themes";
import { REGEX_EMAIL } from "../../validations/validations";
import { LoginActions } from "../../redux/actions";
import newPasswordStyles from "./styles/newPasswordStyles";
import Text from "../../components/common/ui_kit/text";
import Row from "../../components/common/ui_kit/row";
import CustomTextField from "../../components/inputs/custom_textfields";
import strings from "../../utilities/strings";
import CustomButton from "../../components/navigations/custom_buttons";
import CustomLink from "../../components/navigations/custom_links";
import Close_Icon from "../../components/data_display/icons/Close";
import CustomModal from "../../components/inputs/custom_modal";

function FindAccount(props) {
  const [values, setValues] = useState({ email: "", errors: {} });
  const { classes, showFindAccount, toggleModal } = props;
  const { errors } = values;
  const dispatch = useDispatch();
  const history = useHistory();

  const openForgotPassword = () => {
    const dataToSubmit = { email: values.email };
    dispatch(
      LoginActions.forgotPassword(dataToSubmit, (resp) => {
        const error = get(resp, "error.error", false);
        if (error) {
          const { errors } = values;
          errors.apiError = true;
          setValues({ ...values });
        } else {
          toggleModal("showFindAccount", "emailNotifySuccess");
        }
      })
    );
  };

  const redirectTosignIn = () => {
    toggleModal("showFindAccount", "displayLogin");
  };

  const redirectToJoinUs = () => {
    toggleModal("showFindAccount", "displayJoinUs");
  };

  const openRecoverHere = () => {
    toggleModal("showFindAccount", "showRecoverHere");
  };

  const onInputChange = (e) => {
    const { name, value } = e.target;
    const { errors } = values;
    errors.apiError = false;
    const regexTest = REGEX_EMAIL.test(value);
    errors.email = regexTest ? false : true;
    setValues({ ...values, [name]: value });
  };
  return (
    <>
      <CustomModal
        open={showFindAccount}
        aria-labelledby="modal-title"
        disablePortal
        aria-describedby="modal-description"
        disableAutoFocus
      >
        <div className={classes.recover_password_width}>
          <div className={`${classes.textRight} ${classes.margin_top_20}`}>
            {/* <CustomButton
              className={classes.crossBtn}
              onClick={() => {
                toggleModal("showFindAccount");
              }}
            >
              <Close_Icon />
            </CustomButton> */}
          </div>
          <div className={classes.textCenter}>
            <img
              src="/assets/images/recover1.png"
              className={classes.recover_img}
            />
          </div>
          <div className={classes.spacing_padding}>
            <Text
              size={24}
              color={color.primary_palette.black}
              family="gillsans_sb"
              transform="uppercase"
              className={classes.textCenter}
            >
              {strings.passwords.titles.find_account}
            </Text>
            <from>
              <div className={classes.textCenter}>
                {/* <div className={classes.auto_email}>
                <CustomCheckbox
                  label={`Do you mean ${email}com?`}
                  labelPlacement="start"
                  text_transform="none"
                  onChange={onEmailSuggestionChecked(`${email}com`)}
                />
                <CustomInputLabel
                  is_input_label={true}
                  color={color.primary_palette.black}
                  label_name="Yes"
                />
              </div> */}
                <CustomTextField
                  className={`${classes.phn_email_blk}`}
                  label={
                    get(errors, "apiError", false)
                      ? strings.passwords.titles.email_phone_not_recognized
                      : strings.passwords.titles.email_or_phone
                  }
                  error={get(errors, "apiError", false)}
                  name="email"
                  value={get(values, "email", "")}
                  onChange={onInputChange}
                />
              </div>
              <div className={classes.textCenter}>
                <CustomButton
                  className={classes.locateBtn}
                  onClick={openForgotPassword}
                  disabled={
                    get(values, "email.length", 0) < 1 ||
                    get(values, "errors.email", false)
                  }
                >
                  {strings.passwords.titles.reset_pwd_txt}
                </CustomButton>
              </div>
            </from>
            <div className={classes.textCenter}>
              <Text
                size={14}
                color={color.primary_palette.franklin_purple}
                family="gillsans_sb"
              >
                {strings.passwords.titles.Oops}
              </Text>
              <CustomLink underline="always" className={classes.recover}  onClick={openRecoverHere} >
                <label className={classes.cursor}>
                  {strings.passwords.titles.recover_here}
                </label>
              </CustomLink>
            </div>
            <Row className={classes.footer_spacing}>
              <div className={classes.sign_in}>
                <Text
                  size={14}
                  color={color.primary_palette.franklin_purple}
                  family="gillsans_sb"
                >
                  {strings.passwords.titles.Wait_password}
                </Text>
                <CustomLink
                  underline="always"
                  className={classes.recover}
                  onClick={redirectTosignIn}
                >
                  <label className={classes.cursor}>
                    {strings.passwords.titles.sign_in}
                  </label>
                </CustomLink>
              </div>
              <div className={classes.join_here}>
                <Text
                  size={14}
                  color={color.primary_palette.franklin_purple}
                  family="gillsans_sb"
                >
                  {strings.passwords.titles.not_member}
                </Text>
                <CustomLink
                  underline="always"
                  className={classes.recover}
                  onClick={redirectToJoinUs}
                >
                  <label className={classes.cursor}>
                    {strings.passwords.titles.join_here}
                  </label>
                </CustomLink>
              </div>
            </Row>
          </div>
        </div>
      </CustomModal>
    </>
  );
}

export default newPasswordStyles(FindAccount);
