import React from "react";

import Modal from "@material-ui/core/Modal";
import { makeStyles } from "@material-ui/core";
import { pxToRem } from "../../utilities/themes";

const useStyles = makeStyles({
  custom_modal: {
    background: "rgba(255, 255, 255, .90)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    outline: "none",
    "& div[aria-hidden='true']": {
      backgroundColor: "rgba(255, 255, 255, 0.1) !important",
    },
    "& div": {
      "&:nth-child(3)": {
        // backgroundColor: "transparent !important",
        borderRadius: pxToRem(18),
      },
    },
    //  "& div": {
    //       "&:nth-child(1)": {
    //           backgroundColor: "transparent !important",
    //         }
    //   }
  },
});

function CustomModal({ className, children, ...props }) {
  const classes = useStyles();
  return (
    <>
      <Modal
        className={`${classes.custom_modal} ${className}`}
        // BackdropProps	={
        //   classes: {root: classes.root}
        // }
        {...props}
      >
        {children}
      </Modal>
    </>
  );
}

export default CustomModal;
