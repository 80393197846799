import api from ".";
import { URI } from "../../constants";

export default {
  joinUs: (data) => {
    return api.post(URI.JOINUS, data);
  },
  sendThanksEmailNotification: (data) => {
    return api.post(URI.SEND_THANKS, data);
  },
};
