import React, { Component } from "react";
import _ from "lodash";
import { FormControl, Select, MenuItem, Modal } from "@material-ui/core";
import { connect } from "react-redux";

import styleSheet from "./styles/feedback_styles";
import Text from "../common/ui_kit/text";
import CustomTextArea from "../inputs/custom_text_area";
import Ribbon_Icon from "../data_display/icons/Ribbon";
import CustomButton from "../navigations/custom_buttons";
import Close_Icon from "../data_display/icons/Close";
import Element_Required_Icon from "../data_display/icons/ElementRequiered";
import { Row, Tiles, Tile } from "../../components/common/ui_kit";
import tradework_theme, { color, pxToRem } from "../../utilities/themes";
import CustomTextField from "../inputs/custom_textfields";
import { URI, PROFILE_TYPES, LOOKUP_URI } from "../../constants";
import Api, { lookupApiURL } from "../../redux/api";
import { ApiActions, LoginActions, ProfileActions } from "../../redux/actions";

class Feedback extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // comment: "",
      worstAboutTW: "",
      worstAboutPage: "",
      hateWhatTradeworksDoes: "",
      favoritePartAboutTradeworksIs: "",
      error: true,
      email: "",
      userName: "",
      pack: "spack",
      // comment: "",
      // worstAboutTW:""
      // error : true
      // }
    };
  }

  closeModal = () => {
    this.setState({
      closeIcon: false,
    });
    this.props.closeModal();
  };

  onWorstAboutTWBlur = (e) => {
    const { value } = e.target;
    this.setState({
      worstAboutTW: value,
      error: false,
    });
  };

  onWorstAboutPage = (e) => {
    const { value } = e.target;
    this.setState({
      worstAboutPage: value,
      error: false,
    });
  };

  onHateWhatTradeworksDoes = (e) => {
    const { value } = e.target;
    this.setState({
      hateWhatTradeworksDoes: value,
      error: false,
    });
  };

  onFavoritePartAboutTradeworksIs = (e) => {
    const { value } = e.target;
    this.setState({
      favoritePartAboutTradeworksIs: value,
      error: false,
    });
  };

  emailVal = (e) => {
    const { value } = e.target;
    this.setState({
      email: value,
      error: false,
    });
  };

  // onCommentBlur = e => {
  //   const { value } = e.target;
  //   this.setState({
  //     comment: value,
  //     errors: {
  //       ...this.state.errors,
  //       comment: !value
  //     }
  //   });
  // };

  submitFeedback = async () => {
    if (!this.hasFormNoErrors()) {
      return;
    }
    let { user } = this.props;
    let { userName, email } = this.state;
    const obj = {
      name: userName,
      email: email,
      // feedback: this.state.comment
      worstAboutTW: this.state.worstAboutTW,
      worstAboutPage: this.state.worstAboutPage,
      pack:'Spack',
      hateWhatTradeworksDoes: this.state.hateWhatTradeworksDoes,
      favoritePartAboutTradeworksIs: this.state.favoritePartAboutTradeworksIs,
    };

    this.props.dispatch(
      LoginActions.sendFeedback(obj, (resp) => {
        console.log("resp", resp);
      })
    );
    // await lookupApiURL.post(LOOKUP_URI.POST_FEEDBACK, obj);
    this.props.closeModal();
  };

  hasFormNoErrors = () => {
    const {
      worstAboutTW,
      worstAboutPage,
      hateWhatTradeworksDoes,
      favoritePartAboutTradeworksIs,
      email,
    } = this.state;
    return (
      worstAboutTW != "" ||
      worstAboutPage != "" ||
      hateWhatTradeworksDoes != "" ||
      favoritePartAboutTradeworksIs != ""
    );
  };

  render() {
    const { classes, open, user } = this.props;
    const { userName, email } = this.state;
    const formHasNoErrors = this.hasFormNoErrors();
    return (
      <>
        <div className={classes.feedbackDiv}>
          <div className={classes.Interest_width}>
            <div className={classes.textAlign}>
              <CustomButton className={classes.closeBtn}>
                <span
                  className={classes.joinUs_cross}
                  onMouseUp={this.closeModal}
                >
                  X
                </span>
              </CustomButton>
            </div>
            <Row>
              <img
                src="assets/images/Lil-beta-stamp.png"
                className={classes.beta_pos}
              />
            </Row>
            <Row>
              <Text
                family="gillsans_r"
                size={14}
                color={color.primary_palette.franklin_purple}
                className={classes.textFieldAlign}
              >
                The worst thing about this page is...
              </Text>
            </Row>
            <Row>
              <CustomTextField
                className={classes.plain_input}
                defaultValue={""}
                onBlur={this.onWorstAboutTWBlur}
              />
            </Row>
            <Row>
              <Text
                family="gillsans_r"
                size={14}
                color={color.primary_palette.franklin_purple}
                className={classes.textFieldAlign}
              >
                I really do not like this...
              </Text>
            </Row>
            <Row>
              <CustomTextField
                className={classes.plain_input}
                defaultValue={""}
                onBlur={this.onWorstAboutPage}
              />
            </Row>

            <Row>
              <Text
                family="gillsans_r"
                size={14}
                color={color.primary_palette.franklin_purple}
                className={classes.textFieldAlign}
              >
                The worst thing about TradeWorks is...
              </Text>
            </Row>
            <Row>
              <CustomTextField
                className={classes.plain_input}
                defaultValue={""}
                onBlur={this.onHateWhatTradeworksDoes}
              />
            </Row>

            <Row>
              <Text
                family="gillsans_r"
                size={14}
                color={color.primary_palette.franklin_purple}
                className={classes.textFieldAlign}
              >
                My favorite part about TradeWorks is…
              </Text>
            </Row>
            <Row>
              <CustomTextField
                className={classes.plain_input}
                defaultValue={""}
                onBlur={this.onFavoritePartAboutTradeworksIs}
              />
            </Row>
            <Row>
              <Text
                family="gillsans_r"
                size={14}
                color={color.primary_palette.franklin_purple}
                className={`${classes.emailField} ${classes.textFieldAlign}`}
              >
                Your Email
              </Text>
              <CustomTextField
                className={`${classes.plain_input} ${classes.from_input}`}
                value={email}
                // onBlur={this.emailVal}
                // disabled
                onChange={this.emailVal}
              />
              <a
                color="red"
                className={classes.buttonAlign}
                disabled={!formHasNoErrors}
                onMouseUp={this.submitFeedback}
              >
                SEND
              </a>
            </Row>
          </div>
        </div>
      </>
    );
  }
}

// export default styleSheet(Feedback);

export default connect()(styleSheet(Feedback));
