import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { get } from "lodash";
import { useSelector } from "react-redux";

import Join_Us from "../pages/auth/joinus/join_us";
import Default_Landing from "../pages/layouts/default_landing";
import Loading from "../components/common/loading";
import NewPassword from "../pages/password/new_password";
import AppRedirect from "../pages/auth/joinus/app_redirect";
import PasswordRecovery from "../pages/password/pwd_recovery";
import MessagePing from "../pages/thanksModal/message_ping";
import success_modal from "../components/Modals/success_modal/success_modal";

export default function Router(props) {
  const loading = useSelector((state) => state.Api.loading);
  return (
    <BrowserRouter>
      <Loading loading={loading} />
      <Switch>
        <Route
          exact
          path="/thankMail/:email/:token"
          state={{ from: props.location }}
          component={MessagePing}
        />
        <Route exact path="/" component={Default_Landing} />
        <Route
          exact
          path="/join_us"
          component={Join_Us}
          state={{ from: props.location }}
        />
        <Route
          exact
          path="/login"
          component={Join_Us}
          state={{ from: props.location }}
        />
        <Route
          path="/reset-password/:token"
          exact
          component={(props) => <PasswordRecovery {...props} />}
        />
        <Route
          path="/:usertype/:token"
          component={(props) => <AppRedirect {...props} />}
        />
        <Route
          path="/:usertype/profile/:token"
          component={(props) => <AppRedirect {...props} />}
        />
        <Route
          exact
          path="/password"
          component={NewPassword}
          state={{ from: props.location }}
        />
        <Route
          exact
          path="/logout"
          component={(props) => {
            localStorage.clear();
            props.history.push("/");
            return null;
          }}
          state={{ from: props.location }}
        />

        <Redirect from="*" to="/" />
      </Switch>
    </BrowserRouter>
  );
}
