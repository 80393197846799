import React from "react";
import { get } from "lodash";

import { color } from "../../utilities/themes";
import newPasswordStyles from "./styles/newPasswordStyles";
import Text from "../../components/common/ui_kit/text";
import strings from "../../utilities/strings";
import CustomButton from "../../components/navigations/custom_buttons";
import Close_Icon from "../../components/data_display/icons/Close";
import Success_check from "../../components/data_display/icons/Check";
import CustomModal from "../../components/inputs/custom_modal";

function EmailNotifySuccess(props) {
  const { classes, emailNotifySuccess, toggleModal } = props;
  const closeModal = () => {
    toggleModal("emailNotifySuccess", "displayLogin");
  };
  return (
    <>
      <CustomModal
        open={emailNotifySuccess}
        aria-labelledby="modal-title"
        disablePortal
        aria-describedby="modal-description"
        disableAutoFocus
      >
        <div
          className={`${classes.successModalPadding} ${classes.success_width}`}
        >
          <div className={classes.textCenter}>
            <Success_check className={classes.successCheck} />
            <Text
              size={15}
              color={color.primary_palette.black}
              family="gillsans_sb"
              transform="uppercase"
              className={classes.textCenter}
            >
              PLEASE CHECK YOUR EMAIL FOR PASSWORD RESET INSTRUCTIONS.
            </Text>
            <CustomButton className={classes.okBtn} onClick={closeModal}>
              {" "}
              {strings.passwords.titles.ok}
            </CustomButton>
          </div>
        </div>
      </CustomModal>
    </>
  );
}

export default newPasswordStyles(EmailNotifySuccess);
