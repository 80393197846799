import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";

import { TextareaAutosize, FormHelperText } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import { font_sizes, color, pxToRem } from "../../utilities/themes";
import CustomButton from "../navigations/custom_buttons";
import Close_Icon from "../data_display/icons/Close";
import { MAX_INPUT_COUNT } from "../../constants";

const useStyles = {
  num: {
    position: "absolute",
    right: 0,
    fontSize: font_sizes.font_12,
    padding: 0,
    textAlign: "right",
    fontFamily: "avenir_black_r",
    width: "100%",
    fontWeight: "bold",
  },
  cross: {
    position: "absolute",
    right: pxToRem(-24),
    padding: 0,
    top: pxToRem(1),
  },
  cross_font: {
    fontSize: font_sizes.font_10,
  },
  error: {
    border: `solid ${pxToRem(1)} ${color.primary_palette.christmas_red}`,
  },
  txtAreaBorder: {
    "&:focus": {
      border: `solid ${pxToRem(1)} ${
        color.secondary_palette.click_blue
      } !important`,
    },
    "&:hover": {
      border: `solid ${pxToRem(1)} ${
        color.secondary_palette.hover_blue
      } !important`,
    },
    "&:active": {
      border: `solid ${pxToRem(1)} ${
        color.secondary_palette.click_blue
      } !important`,
    },
    "&:focus-within": {
      border: `solid ${pxToRem(1)} ${
        color.secondary_palette.click_blue
      } !important`,
    },
  },
  override: {
    float: "right",
    padding: `${pxToRem(3)} ${pxToRem(0)}`,
    zIndex: 999,
  },
  para: {
    color: color.primary_palette.christmas_red,
    margin: 0,
    display: "inline-block",
  },
};

class CustomTextArea extends Component {
  constructor(props) {
    super(props);
    this.state = {
      text: "",
    };
  }

  static propTypes = {
    onBlur: PropTypes.func,
    onClear: PropTypes.func,
    className: PropTypes.string,
    classes: PropTypes.object,
    defaultValue: PropTypes.string,
    enableCount: PropTypes.bool,
    countValue: PropTypes.string,
    enableClear: PropTypes.bool,
    placeholder: PropTypes.string,
  };

  static defaultProps = {
    defaultValue: "",
    placeholder: "",
    enableCount: false,
    enableClear: false,
  };

  componentDidMount() {
    this.setState({ text: this.props.defaultValue });
  }

  componentDidUpdate(prevProps) {
    if (this.props.defaultValue !== prevProps.defaultValue) {
      this.setState({ text: this.props.defaultValue });
    }
  }

  onChange = (e, callBack, hasError, index) => {
    const value = e.target.value.trimStart();
    const LIMIT = this.props.countValue || MAX_INPUT_COUNT;
    const remainingLimit = LIMIT - value.length;
    if (Number(remainingLimit) < 0) {
      if (!hasError) {
        callBack && callBack({ error: true, index });
      }
    } else {
      if (hasError) {
        callBack && callBack({ error: false, index });
      }
    }
    this.setState({ text: value });
  };

  onClear = () => {
    this.setState({ text: "" }, () => {
      this.props.onClear && this.props.onClear();
    });
  };

  render() {
    const { text } = this.state;
    const {
      className,
      classes,
      enableCount,
      enableClear,
      countValue,
      error,
      override,
      onOverride,
      errorIcon,
      callBack,
      hasError,
      index,
      countColor,
      ...rest
    } = this.props;

    const remainingLimit = this.props.countValue
      ? this.props.countValue - _.get(text, "length")
      : MAX_INPUT_COUNT - _.get(text, "length");

    return (
      <>
        <TextareaAutosize
          {...rest}
          className={`${className} ${error && classes.error} ${
            classes.txtAreaBorder
          }`}
          value={text}
          onChange={(e) => this.onChange(e, callBack, hasError, index)}
        />
        {error && (
          <div>
            <p className={classes.para}>
              {errorIcon}
              {error}
            </p>
            {override && (
              <CustomButton className={classes.override} onClick={onOverride}>
                <img src="/assets/icons/Override.svg" />
              </CustomButton>
            )}
          </div>
        )}
        {(enableCount || enableClear) && (
          <div>
            {enableCount && (
              <span
                className={classes.num}
                style={{
                  ...(remainingLimit > 0 &&
                    remainingLimit <= 50 && {
                      color: color.accent_colors_and_one_offs.links_orange,
                    }),
                  ...(remainingLimit <= 0 && {
                    color: color.primary_palette.tricks_red,
                  }),
                  ...(remainingLimit > 50 && {
                    color: countColor,
                  }),
                }}
              >
                {remainingLimit}
              </span>
            )}
            {enableClear && (
              <CustomButton className={classes.cross} onMouseUp={this.onClear}>
                <Close_Icon className={classes.cross_font} />
              </CustomButton>
            )}
          </div>
        )}
      </>
    );
  }
}

export default withStyles(useStyles, { withTheme: true })(CustomTextArea);
