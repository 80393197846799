import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { get } from "lodash";

import { color } from "../../utilities/themes";
import { JoinUsActions } from "../../redux/actions";
import CustomButton from "../../components/navigations/custom_buttons";
import Close_Icon from "../../components/data_display/icons/Close";
import MessagePingStyles from "./styles/messagePingStyles";
import Text from "../../components/common/ui_kit/text";
import strings from "../../utilities/strings";
import CustomTextArea from "../../components/inputs/custom_text_area";
import CustomModal from "../../components/inputs/custom_modal";

function MessagePing(props) {
  const { classes } = props;
  const [values, setValues] = useState({
    message: "",
    email: "",
    token: "",
  });
  const { email } = values;
  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    const { email, token } = props.match.params;
    setValues({ ...values, email, token });
  }, []);

  const handleInputChange = (name) => (e) => {
    const { value } = e.target;
    setValues({ ...values, [name]: value });
  };

  const handleSubmit = () => {
    const { message, token } = values;
    dispatch(
      JoinUsActions.sendThanksEmail({ message, token }, (resp) => {
        const error = get(resp, "data.error.error");
        if (!error) {
          history.push("/");
        }
      })
    );
  };

  return (
    <CustomModal open={true}>
      <div className={classes.message_width}>
        <div className={classes.btnRight}>
          <CustomButton className={classes.crossBtn}>
            <Close_Icon />
          </CustomButton>
        </div>
        <div className={classes.textCenter}>
          <img
            src="/assets/images/trade-works.png"
            className={classes.tradeworks_icon}
          />
          <div>
            <Text
              size={20}
              color={color.primary_palette.black}
              family="avenir_bold"
              fontWeight={900}
            >
              {strings.modals.titles.message_to} <br />
              {email}
            </Text>
          </div>
          <div>
            <CustomTextArea
              className={classes.textArea}
              onBlur={handleInputChange("message")}
              placeholder="Thank you working on it"
            />
          </div>
          <CustomButton className={classes.sendBtn} onClick={handleSubmit}>
            {strings.modals.titles.Send}
          </CustomButton>
        </div>
      </div>
    </CustomModal>
  );
}

export default MessagePingStyles(MessagePing);
