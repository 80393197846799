import _ from "lodash";
import { LoginTypes } from "../actionTypes";

const initialState = {
  joinUsFormData: {},
  openSucessModal: false,
  clearForm: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LoginTypes.JOIN_US: {
      const success = action.data.data.errorCode == 200;
      return {
        ...state,
        joinUsFormData: action.data,
        openSucessModal: success,
        clearForm: success,
      };
    }
    default:
      return state;
  }
};
