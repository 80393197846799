import { combineReducers } from "redux";
import ApiReducer from "./api.reducer";
import JoinUsReducer from "./joinus_reducer";
import LoginReducer from "./login_reducer";

const appReducer = combineReducers({
  Api: ApiReducer,
  JoinUs: JoinUsReducer,
  Login: LoginReducer,
});

const RootReducer = (state, action) => {
  // if (action.type === LoginTypes.LOGOUT) {
  //   localStorage.clear();
  //   state = undefined;
  // }
  return appReducer(state, action);
};

export default RootReducer;
