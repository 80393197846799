import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { get } from "lodash";

function PasswordRecovery(props) {
  const history = useHistory();
  useEffect(() => {
    const token = get(props, "match.params.token", "");
    history.push({
      pathname: "/",
      state: { recoveryToken: token },
    });
  }, []);
  return null;
}

export default PasswordRecovery;
