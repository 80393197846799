import api, { lookupApiURL } from ".";
import { LOOKUP_URI, URI } from "../../constants";

export default {
  login: (data) => {
    return api.post(URI.LOGIN, data);
  },
  forgotPassword: (data) => {
    return api.post(URI.FORGOT_PWD, data);
  },
  setNewPassword: (data) => {
    return api.post(URI.RESET_PASSWORD, data);
  },
  postFeedback: (data) => {
    return lookupApiURL.post(LOOKUP_URI.POST_FEEDBACK, data);
  },
  tradeLocations: (data) => {
    return lookupApiURL.post(LOOKUP_URI.TRADE_LOCATIONS, data);
  },
};
