import React from "react";
import newPasswordStyles from "./styles/newPasswordStyles";
import Text from "../../components/common/ui_kit/text";
import Row from "../../components/common/ui_kit/row";
import CustomModal from "../../components/inputs/custom_modal";
import { color } from "../../utilities/themes";
import Close_Icon from "../../components/data_display/icons/Close";

function FindEmail(props) {
  const { classes, showFindEmail, toggleModal } = props;

  const redirectTosignIn = () => {
    toggleModal("showFindEmail", "displayLogin");
  };

  return (
    <>
      <CustomModal
        open={showFindEmail}
        aria-labelledby="modal-title"
        disablePortal
        aria-describedby="modal-description"
        disableAutoFocus
      >
        <div className={classes.recover_password_width}>
          <div>
            <Close_Icon
              onClick={redirectTosignIn}
              className={classes.closeAlign}
            />
          </div>

          <div className={classes.textCenter}>
            <img
              src="/assets/images/Help Icon .svg"
              className={classes.forgot_email_img}
            />
          </div>
          <Text
            size={16}
            color={color.primary_palette.black}
            family="gillsans_sb"
            // transform="uppercase"
            className={classes.textCenter}
          >
            Please contact us at 212.639.9100 or
          </Text>
          <Text
            size={16}
            color={color.primary_palette.black}
            family="gillsans_sb"
            // transform="uppercase"
            className={classes.textCenter}
            style={{ marginBottom: "15px" }}
          >
            editor@franklin.works for help with your email
          </Text>
        </div>
      </CustomModal>
    </>
  );
}

export default newPasswordStyles(FindEmail);
