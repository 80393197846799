import { withStyles } from "@material-ui/core/styles";
import tradework_theme, { pxToRem, color } from "../../../utilities/themes";

const styles = withStyles({
  message_width: {
    width: pxToRem(335),
    border: `solid ${pxToRem(2)} ${color.primary_palette.franklin_purple}`,
    backgroundColor: color.primary_palette.fafa_gray,
    "&:focus": {
      outline: "none",
    },
  },
  btnRight: {
    textAlign: "right",
  },
  crossBtn: {
    minWidth: pxToRem(30),
    "& .MuiSvgIcon-root": {
      fontSize: pxToRem(15),
      padding: pxToRem(10),
    },
  },
  textCenter: {
    textAlign: "center",
  },
  tradeworks_icon: {
    width: pxToRem(104),
  },
  textArea: {
    width: pxToRem(253),
    margin: "0 auto",
    height: `${pxToRem(37)} !important`,
    resize: "none",
    marginTop: pxToRem(16),
    ...tradework_theme.typography.styles.gillsans_light,
    color: color.primary_palette.black,
    fontSize: pxToRem(18),
    padding: `${pxToRem(12)} ${pxToRem(14)}`
  },
  sendBtn: {
    width: pxToRem(123),
    height: pxToRem(28),
    borderRadius: pxToRem(14),
    border: `solid ${pxToRem(1)} ${color.primary_palette.franklin_purple}`,
    backgroundColor: color.primary_palette.white,
    color: `${color.primary_palette.franklin_purple} !important`,
    fontSize: pxToRem(20),
    ...tradework_theme.typography.styles.avenir_black_r,
    marginTop: pxToRem(18),
    marginBottom: pxToRem(26),
    "&:hover": {
      backgroundColor: `${color.button_hover} !important`
    }
  },
});

export default styles;
