import { get } from "lodash";
import { TOKEN_KEY } from "../../constants";
import { LoginTypes } from "../actionTypes";

const initialState = {
  loggingIn: false,
  loginError: null,
  user: null,
  openLoginModal: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LoginTypes.CLEAR_LOGIN_PROPS: {
      return {
        ...state,
        loggingIn: false,
        loginError: null,
      };
    }
    case LoginTypes.LOGIN_USER: {
      return {
        ...state,
        loggingIn: true,
      };
    }
    case LoginTypes.TRADE_LOCATIONS: {
      return {
        ...state,
        topLocations: action.data,
      };
    }
    case LoginTypes.LOGIN_USER_SUCCESS: {
      const data = action.data;
      localStorage.setItem("profile", JSON.stringify(data));
      localStorage.setItem(TOKEN_KEY, data.token);
      localStorage.setItem("user_data", get(data, "user.firstName", ""));
      return {
        ...state,
        loggingIn: false,
        loginError: null,
        user: data,
      };
    }
    case LoginTypes.LOGIN_USER_FAIL: {
      const error = action.payload;
      return {
        ...state,
        loggingIn: false,
        loginError: error,
      };
    }
    case LoginTypes.CLOSE_MODAL: {
      return {
        ...state,
        openLoginModal: false,
        loginError: null,
      };
    }
    default:
      return state;
  }
};
