import React, { useEffect, useState } from "react";
import { get } from "lodash";
import { InputAdornment } from "@material-ui/core";

import newPasswordStyles from "./styles/newPasswordStyles";
import Text from "../../components/common/ui_kit/text";
import CustomTextField from "../../components/inputs/custom_textfields";
import strings from "../../utilities/strings";
import CustomButton from "../../components/navigations/custom_buttons";
import Close_Icon from "../../components/data_display/icons/Close";
import ShowPassword_Icon from "../../components/data_display/icons/ShowPassword";
import HidePassword_Icon from "../../components/data_display/icons/HidePassword";
import CustomModal from "../../components/inputs/custom_modal";
import { color } from "../../utilities/themes";
import {
  ATLEAST_ONE_CAPITAL_LETTER,
  NUM_OR_SYMBOL,
} from "../../validations/validations";
import { LoginActions } from "../../redux/actions";
import { useDispatch } from "react-redux";

function NewPassword(props) {
  const { classes, showNewPassword, toggleModal, token } = props;
  const [values, setValues] = useState({ showPassword: false, errors: {} });
  const { showPassword, errors, password } = values;

  const dispatch = useDispatch();

  const hasEightChars = (value) => {
    return value.length >= 8;
  };

  const hasUpperCaseLetter = (value) => {
    return ATLEAST_ONE_CAPITAL_LETTER.test(value);
  };

  const hasNumOrSymbol = (value) => {
    return NUM_OR_SYMBOL.test(value);
  };

  const handleShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleInputChange = (e) => {
    const { value, name } = e.target;
    const { errors } = values;
    const hasEight = hasEightChars(value);
    const numOrSymbol = hasNumOrSymbol(value);
    const hasCaps = hasUpperCaseLetter(value);
    if (!value) {
      errors[name] = {
        error: true,
        touched: true,
        message: strings.join_us.errors.password_required,
        hasEight,
        numOrSymbol,
        hasCaps,
      };
      setValues({ ...values, errors, [name]: value });
      return;
    }
    if (value) {
      if (hasEight && numOrSymbol && hasCaps) {
        errors[name] = {
          error: false,
          message: "",
          hasEight,
          numOrSymbol,
          hasCaps,
          touched: true,
        };
      } else {
        errors[name] = {
          error: true,
          message: "",
          hasEight,
          numOrSymbol,
          hasCaps,
          touched: true,
        };
      }
      setValues({ ...values, errors, [name]: value });
    }
  };

  const handleSubmitPassword = () => {
    dispatch(
      LoginActions.setNewPassword({ token: token, password }, (resp) => {
        if (!get(resp, "data.error.error", false)) {
          handleSuccess();
        }
      })
    );
  };

  const handleSuccess = () => {
    toggleModal("showNewPassword", "pwdSuccess");
  };
  return (
    <>
      <CustomModal
        open={showNewPassword}
        aria-labelledby="modal-title"
        disablePortal
        aria-describedby="modal-description"
        disableAutoFocus
      >
        <div className={classes.recover_password_width}>
          <div className={classes.textRight}>
            <CustomButton
              className={classes.crossBtn}
              onClick={() => {
                toggleModal("showNewPassword");
              }}
            >
              <Close_Icon />
            </CustomButton>
          </div>
          <div className={classes.textCenter}>
            <img
              src="/assets/images/lock.png"
              className={classes.lock_img_new}
            />
          </div>
          <Text
            size={24}
            color={color.primary_palette.black}
            family="gillsans_sb"
            transform="uppercase"
            className={classes.textCenter}
          >
            {strings.passwords.titles.enter_new_password}
          </Text>
          <from>
            <div className={classes.textCenter}>
              <CustomTextField
                className={classes.password_width}
                label={strings.passwords.titles.enter_password}
                type={showPassword ? "text" : "password"}
                name="password"
                value={get(values, "password", "")}
                onChange={handleInputChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      tabIndex="-1"
                      aria-label="toggle password visibility"
                      className={classes.password_icon}
                    >
                      {showPassword && (
                        <ShowPassword_Icon
                          color={color.primary_palette.franklin_purple}
                          onClick={handleShowPassword}
                          style={{
                            fontSize: "1.3125rem",
                            cursor: "pointer",
                          }}
                        />
                      )}
                      {!showPassword && (
                        <HidePassword_Icon
                          onClick={handleShowPassword}
                          style={{
                            fontSize: "1.3125rem",
                            cursor: "pointer",
                          }}
                        />
                      )}
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <div>
              <Text
                size={12}
                color={color.form_colors.textfield_color}
                family="gillsans_light"
                className={classes.textCenter}
              >
                <span
                  className={
                    !get(errors, "password.touched", false)
                      ? classes.password_validation
                      : get(errors, "password.hasEight", false)
                      ? classes.successText
                      : classes.errorText
                  }
                >
                  <span className={classes.char_num}>8</span> characters.
                </span>
                <span
                  className={
                    !get(errors, "password.touched", false)
                      ? classes.password_validation
                      : get(errors, "password.hasCaps", false)
                      ? classes.successText
                      : classes.errorText
                  }
                >
                  <span className={classes.char_num}>1</span> letter.{" "}
                </span>
                <span
                  className={
                    !get(errors, "password.touched", false)
                      ? classes.password_validation
                      : get(errors, "password.numOrSymbol", false)
                      ? classes.successText
                      : classes.errorText
                  }
                >
                  <span className={classes.char_num}>1</span> number or symbol
                </span>
              </Text>
            </div>
            <div className={classes.textCenter}>
              <CustomButton
                className={classes.set_new_password}
                onClick={handleSubmitPassword}
                disabled={get(errors, "password.error", false) || !password}
              >
                {strings.passwords.titles.set_new_password}
              </CustomButton>
            </div>
          </from>
        </div>
      </CustomModal>
    </>
  );
}

export default newPasswordStyles(NewPassword);
