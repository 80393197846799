const types = {
  JOIN_US: "JOIN_US",
  RESET_ERR_MSG: "RESET_ERR_MSG",
  CLEAR_ALL_FIELDS: "CLEAR_ALL_FIELDS",
  LOGIN_USER_SUCCESS: "LOGIN_USER_SUCCESS",
  LOGIN_USER_FAIL: "LOGIN_USER_FAIL",
  CLEAR_LOGIN_PROPS: "CLEAR_LOGIN_PROPS",
  CLOSE_MODAL: "CLOSE_MODAL",
  LOGIN_USER: "LOGIN_USER",
  LOGOUT: "LOGOUT",
  TRADE_LOCATIONS: "TRADE_LOCATIONS",
};

export default types;
