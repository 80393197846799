import axios from "axios";
import { SERVER_URL, SERVER_URL_LOOKUP } from "../../constants";
import { RequestInterceptor } from "./request_interceptor";
import { ResponseInterceptor } from "./response_interceptor";
import { default as JoinusApi } from "./joinUs_api";
import { default as LoginApi } from "./login_api";

const api = axios.create({
  baseURL: SERVER_URL,
});

const lookupApiURL = axios.create({
  baseURL: SERVER_URL_LOOKUP,
});

api.interceptors.request.use(RequestInterceptor);
api.interceptors.response.use(null, ResponseInterceptor);

lookupApiURL.interceptors.request.use(RequestInterceptor);
lookupApiURL.interceptors.response.use(null, ResponseInterceptor);

export default api;
export { LoginApi, JoinusApi, lookupApiURL };
