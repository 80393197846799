import React from "react";

import { color } from "../../utilities/themes";
import newPasswordStyles from "./styles/newPasswordStyles";
import Text from "../../components/common/ui_kit/text";
import strings from "../../utilities/strings";
import CustomButton from "../../components/navigations/custom_buttons";
import Close_Icon from "../../components/data_display/icons/Close";
import Ribbon_Icon from "../../components/data_display/icons/Ribbon";
import CustomModal from "../../components/inputs/custom_modal";

function SuccessModal(props) {
  const { classes, pwdSuccess, toggleModal } = props;
  const handleSuccess = () => {
    toggleModal("pwdSuccess", "displayLogin");
  };
  return (
    <>
      <CustomModal
        open={pwdSuccess}
        aria-labelledby="modal-title"
        disablePortal
        aria-describedby="modal-description"
        disableAutoFocus
      >
        <div className={classes.success_width}>
          <div className={classes.textRight}>
            <CustomButton className={classes.crossBtn}>
              <Close_Icon />
            </CustomButton>
          </div>
          <div className={classes.textCenter}>
            <Ribbon_Icon className={classes.ribbon} color="primary" />
            <Text
              size={24}
              color={color.primary_palette.black}
              family="gillsans_sb"
              transform="uppercase"
              className={classes.textCenter}
            >
              {strings.passwords.titles.set}
            </Text>
            <CustomButton className={classes.okBtn} onClick={handleSuccess}>
              {" "}
              {strings.passwords.titles.ok}
            </CustomButton>
          </div>
        </div>
      </CustomModal>
    </>
  );
}

export default newPasswordStyles(SuccessModal);
