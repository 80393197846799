import React, { useState } from "react";
import { Breadcrumbs, Grid, InputAdornment, Modal } from "@material-ui/core";
import { get } from "lodash";
import { useDispatch } from "react-redux";

import CustomInputLabel from "../../../components/data_display/custom_label";
import Close_Icon from "../../../components/data_display/icons/Close";
import Element_Required_Icon from "../../../components/data_display/icons/ElementRequiered";
import HidePassword_Icon from "../../../components/data_display/icons/HidePassword";
import Ribbon from "../../../components/data_display/icons/Ribbon";
import ShowPassword_Icon from "../../../components/data_display/icons/ShowPassword";
import CustomCheckbox from "../../../components/inputs/custom_checkbox";
import CustomTextField from "../../../components/inputs/custom_textfields";
import CustomButton from "../../../components/navigations/custom_buttons";
import CustomLink from "../../../components/navigations/custom_links";

import {
  ATLEAST_ONE_CAPITAL_LETTER,
  NUM_OR_SYMBOL,
  REGEX_EMAIL,
  PHONE_NUMBER_FORMAT,
  REGEX_EMIAL_END_WITH_DOT,
  NUMERIC_REGEX,
  NUMERIC_SPECIAL_REGEX,
} from "../../../validations/validations";
import strings from "../../../utilities/strings";
import { color } from "../../../utilities/themes";
import CustomModal from "../../../components/inputs/custom_modal";
import Chevron_Down_Icon from "../../../components/data_display/icons/Arrow_Chevron_Down";
import styleSheet from "../../styles/join_us_styles";
import { JoinUsActions } from "../../../redux/actions";

function Joinus(props) {
  const [values, setValues] = useState({
    showEmailSuggestion: false,
    showPassword: false,
    countryCode: "USA",
    formData: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      phonenumber: "",
      userType: "",
    },
    errors: {
      firstName: { touched: false },
      lastName: { touched: false },
      password: null,
      email: null,
    },
    popoverModal: true,
  });
  const { classes, displayWelcome, signUpError, openLoginModal } = props;

  const dispatch = useDispatch();

  const closePopoverModal = () => {
    setValues({ ...values, popoverModal: false });
  };

  const handleClickShowPassword = () => {
    const { showPassword } = values;
    setValues({ ...values, showPassword: !showPassword });
  };

  const triggerCountryDropdown = () => {
    const { showCountryDropdown } = values;
    setValues({ ...values, showCountryDropdown: !showCountryDropdown });
  };

  const handleCountryChange = (value) => {
    const { showCountryDropdown, formData } = values;
    formData.phonenumber = "";
    setValues({
      ...values,
      countryCode: value,
      showCountryDropdown: !showCountryDropdown,
    });
  };

  const hasEightChars = (value) => {
    return value.length >= 8;
  };

  const hasUpperCaseLetter = (value) => {
    return ATLEAST_ONE_CAPITAL_LETTER.test(value);
  };

  const hasNumOrSymbol = (value) => {
    return NUM_OR_SYMBOL.test(value);
  };

  const handleValidation = (name, value) => {
    const { errors } = values;
    errors[name] = errors[name] || {};
    if (name === "firstName" || name === "lastName") {
      // VALIDATION FOR FIRST NAME AND LAST NAME
      if (!value || value.length > 35) {
        errors[name] = {
          error: true,
          message: strings.join_us.errors.firstName_min_max,
          touched: true,
        };
      } else {
        errors[name] = {
          error: false,
          message: "",
          touched: true,
        };
      }
      setValues({
        ...values,
        errors,
      });
    }
    if (name === "email") {
      // VALIDATION FOR EMAIL
      if (!value) {
        errors[name] = {
          error: true,
          message: strings.join_us.errors.email_address_required,
          touched: true,
        };
        setValues({ ...values, errors });
        return;
      }
      if (value) {
        // IF EMAIL HAS VALUE
        const regexTest = REGEX_EMAIL.test(value);
        if (!regexTest) {
          errors[name] = {
            error: true,
            message: strings.join_us.errors.invalid_email_address,
            touched: true,
          };
        } else {
          errors[name] = {
            error: false,
            message: "",
            touched: true,
          };
          setValues({ ...values, errors });
        }
      }
    }
    if (name === "password") {
      const hasEight = hasEightChars(value);
      const numOrSymbol = hasNumOrSymbol(value);
      const hasCaps = hasUpperCaseLetter(value);
      if (!value) {
        errors[name] = {
          error: true,
          touched: true,
          message: strings.join_us.errors.password_required,
          hasEight,
          numOrSymbol,
          hasCaps,
        };
        setValues({ ...values, errors });
        return;
      }
      if (value) {
        if (hasEight && numOrSymbol && hasCaps) {
          errors[name] = {
            error: false,
            message: "",
            hasEight,
            numOrSymbol,
            hasCaps,
            touched: true,
          };
        } else {
          errors[name] = {
            error: true,
            message: "",
            hasEight,
            numOrSymbol,
            hasCaps,
            touched: true,
          };
        }
        setValues({ ...values, errors });
      }
    }
    if (name === "phonenumber") {
      const { countryCode } = values;
      if (countryCode === "USA" && value && value.length !== 10) {
        errors[name] = {
          error: true,
          message: "",
          touched: true,
        };
      } else if (
        countryCode === "nonUSA" &&
        value &&
        (value.length < 10 || value.length > 20)
      ) {
        errors[name] = {
          error: false,
          message: "",
          touched: true,
        };
      } else {
        errors[name] = {
          error: false,
          message: "",
          touched: true,
        };
      }
      setValues({ ...values, errors });
    }
  };

  const handlePasswordValidation = (e) => {
    handleValidation(e.target.name, e.target.value);
  };

  const onInputBlur = (event, blur) => {
    const { formData } = values;
    if (event.target.name === "email") {
      // IF EMAIL MATCHES WITH REGEX DISPLAY SUGGESTION AND VALIDATING
      let showSuggestion = false;

      formData[event.target.name] = event.target.value;
      blur && handleValidation(event.target.name, event.target.value);
      if (REGEX_EMIAL_END_WITH_DOT.test(event.target.value)) {
        showSuggestion = true;
      }
      setValues({ ...values, formData, showEmailSuggestion: showSuggestion });
      return;
    }
    if (event.target.name === "phonenumber") {
      // DEFORMATTING PHONE NUMBER AND SENDING TO VALIDATION
      const val = deformatPhoneNumber(event.target.value);

      if (countryCode === "USA" && isNaN(val)) {
        return;
      }
      if (
        countryCode === "nonUSA" &&
        !NUMERIC_SPECIAL_REGEX.test(event.target.value)
      ) {
        return;
      }
      blur && handleValidation(event.target.name, val);
      formData[event.target.name] =
        countryCode !== "nonUSA" ? val : event.target.value;
    } else {
      const val = deformatPhoneNumber(event.target.value);
      formData[event.target.name] = event.target.value;
      blur && handleValidation(event.target.name, val);
    }
    setValues({ ...values, formData });
  };

  const onEmailSuggestionChecked = (value) => (e) => {
    const { formData } = values;
    formData.email = value;
    handleValidation("email", value);
    setValues({ ...values, formData, showEmailSuggestion: false });
  };
  const formatPhoneNumber = (value) => {
    return value.replace(PHONE_NUMBER_FORMAT, "$1.$2.$3");
  };

  const deformatPhoneNumber = (number) => {
    return number.replace(/[().\s/]/g, "");
  };

  const onPhoneNumberFocus = () => {
    const { formData } = values;
    formData.phonenumber = formData.phonenumber.replace(/[().\s/]/g, "");
    setValues({ ...values, formData });
  };

  const hasFromErrors = () => {
    const { errors } = values;
    const allFields = Object.keys(errors);
    if (allFields.length === 0) {
      return true;
    }
    const allErrors = allFields.map((field) => {
      if (field === "phonenumber" && !errors[field]) {
        return false;
      }
      return get(errors[field], "error", true);
    });
    return allErrors.includes(true);
  };
  // const handleUserTypeChange = (value) => () => {
  //   const { formData } = values;
  //   formData.userType = value;
  //   setValues({ ...values });
  //   submitForm()
  // };
  const submitForm = (userType) => {
    const { formData } = values;
    formData.userType = userType;
    setValues({ ...values });
    dispatch(
      JoinUsActions.joinUs({ ...formData }, (resp) => {
        if (get(resp, "error.message.code", false) === 1011) {
          signUpError("You're already a member!", formData.email);
          openLoginModal();
        }
        if (get(resp, "error.code", false) === 1012) {
          const { errors } = values;
          errors["phonenumber"] = {
            error: true,
            message: "Phone Number Already in Use",
            touched: true,
          };
          setValues({ ...values, errors });
        }
        if (get(resp, "data.token", false)) {
          localStorage.setItem("firstName", get(values, "formData.firstName"));
          localStorage.setItem("lastName", get(values, "formData.lastName"));
          displayWelcome();
        }
      })
    );
  };

  const onClearInput = (name) => () => {
    const { formData } = values;
    formData[name] = "";
    handleValidation(name, "");
    setValues({ ...values, showEmailSuggestion: false });
  };

  const handleShowTinyModal = () => {
    const { formData, errors } = values;
    if (
      !get(errors, "firstName.touched", false) ||
      !get(errors, "lastName.touched", false) ||
      get(errors, "lastName.error", false) ||
      get(errors, "firstName.error", false)
    ) {
      return true;
    }
    return false;
  };

  const {
    showEmailSuggestion,
    showPassword,
    showCountryDropdown,
    countryCode,
    popoverModal,
    formData: { firstName, lastName, phonenumber, email, password, userType },
    errors,
  } = values;
  const disableSubmit = hasFromErrors();
  const showTinyPopover = handleShowTinyModal();
  return (
    <>
      <CustomModal
        open
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        className={classes.modal_popup}
      >
        <Grid
          container
          direction="column"
          justify="center"
          alignItems="center"
          className={classes.form_root}
        >
          <form className={classes.form} autoComplete="off">
            <Grid container justify="flex-end" md={12}>
              <CustomButton className={classes.closeBtn}>
                <Close_Icon
                  className={classes.joinUs_cross}
                  onClick={() => props.closeJoinUs()}
                />
              </CustomButton>
            </Grid>
            <div className={classes.form_padding}>
              <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
              >
                <img
                  src="/assets/images/Join Us Log.svg"
                  alt="img"
                  className={classes.dragon_width}
                />
              </Grid>
              {/* <div className={classes.join_us_label}>
                {strings.landing_page.titles.join_us}
              </div> */}
              <div>
                <CustomTextField
                  label={strings.join_us.titles.firstName}
                  clearIcon
                  onClear={onClearInput("firstName")}
                  key={firstName}
                  className={`${
                    !get(errors, "firstName.touched", false)
                      ? classes.inp_initial
                      : get(errors, "firstName.error", false)
                      ? classes.inp_error
                      : classes.inp_success
                  } ${classes.textFieldSuccess}`}
                  clearIcon={true}
                  onBlur={(e) => onInputBlur(e, true)}
                  name={"firstName"}
                  defaultValue={firstName}
                  icon={
                    <Element_Required_Icon
                      className={
                        get(errors, "firstName.error", false)
                          ? classes.errorIcon
                          : classes.requiredIcon
                      }
                      color={color.form_colors.blueberry_purple}
                    />
                  }
                />
                {get(errors, "firstName.error", false) && (
                  <span className={classes.errorText}>
                    {strings.join_us.errors.firstName_min_max}
                  </span>
                )}
              </div>
              <div>
                <CustomTextField
                  onBlur={(e) => onInputBlur(e, true)}
                  clearIcon
                  key={lastName}
                  onClear={onClearInput("lastName")}
                  name={"lastName"}
                  defaultValue={lastName}
                  label={strings.join_us.titles.lastName}
                  className={`${
                    !get(errors, "lastName.touched", false)
                      ? classes.inp_initial
                      : get(errors, "lastName.error", false)
                      ? classes.inp_error
                      : classes.inp_success
                  } ${classes.textFieldSuccess}`}
                  clearIcon={true}
                  icon={
                    <Element_Required_Icon
                      className={
                        get(errors, "lastName.error", false)
                          ? classes.errorIcon
                          : classes.requiredIcon
                      }
                    />
                  }
                />
                {get(errors, "lastName.error", false) && (
                  <span className={classes.errorText}>
                    {strings.join_us.errors.firstName_min_max}
                  </span>
                )}
              </div>
              {showEmailSuggestion && (
                <div className={classes.auto_email}>
                  <CustomCheckbox
                    label={`Do you mean ${email}com?`}
                    labelPlacement="start"
                    text_transform="none"
                    onChange={onEmailSuggestionChecked(`${email}com`)}
                  />
                  <CustomInputLabel
                    className={classes.prediction_yes}
                    is_input_label={true}
                    label_name="Yes"
                    style={{ color: "#410166" }}
                  />
                </div>
              )}
              <div>
                <CustomTextField
                  onChange={onInputBlur}
                  clearIcon
                  onClear={onClearInput("email")}
                  onBlur={(e) => onInputBlur(e, true)}
                  name={"email"}
                  value={email}
                  label={
                    !showEmailSuggestion && strings.join_us.titles.email_address
                  }
                  className={`${
                    !get(errors, "email.touched", false)
                      ? classes.inp_initial
                      : get(errors, "email.error", false)
                      ? classes.inp_error
                      : classes.inp_success
                  } ${classes.textFieldSuccess}`}
                  clearIcon={true}
                  icon={
                    <Element_Required_Icon
                      className={
                        get(errors, "email.error", false)
                          ? classes.errorIcon
                          : classes.requiredIcon
                      }
                    />
                  }
                />
              </div>
              <div>
                <CustomTextField
                  clearIcon
                  onBlur={(e) => onInputBlur(e, true)}
                  name={"password"}
                  key={password}
                  onChange={(e) => handlePasswordValidation(e)}
                  defaultValue={password}
                  type={showPassword ? "text" : "password"}
                  label={strings.join_us.titles.password}
                  className={`${
                    !get(errors, "password.touched", false)
                      ? classes.inp_initial
                      : get(errors, "password.error", false)
                      ? classes.inp_error
                      : classes.inp_success
                  } ${classes.textFieldSuccess}`}
                  clearIcon={true}
                  icon={
                    <Element_Required_Icon
                      className={
                        get(errors, "password.error", false)
                          ? classes.errorIcon
                          : classes.requiredIcon
                      }
                    />
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        tabIndex="-1"
                        aria-label="toggle password visibility"
                        className={classes.password_icon}
                        onClick={handleClickShowPassword}
                      >
                        {password.length > 0 && (
                          <Close_Icon
                            onMouseUp={onClearInput("password")}
                            style={{
                              height: "7px",
                              width: "7px",
                              cursor: "pointer",
                              zIndex: "1000",
                              marginRight: "10px",
                            }}
                          />
                        )}
                        {showPassword ? (
                          <ShowPassword_Icon
                            color={color.primary_palette.franklin_purple}
                            style={{
                              fontSize: "1.3125rem",
                              cursor: "pointer",
                            }}
                          />
                        ) : (
                          <HidePassword_Icon
                            style={{
                              fontSize: "1.3125rem",
                              cursor: "pointer",
                            }}
                          />
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <div>
                <span
                  className={
                    !get(errors, "password.touched", false)
                      ? classes.password_validation
                      : get(errors, "password.hasEight", false)
                      ? classes.successText
                      : classes.errorText
                  }
                >
                  {strings.join_us.messages.eight_chars}.{" "}
                </span>
                <span
                  className={
                    !get(errors, "password.touched", false)
                      ? classes.password_validation
                      : get(errors, "password.hasCaps", false)
                      ? classes.successText
                      : classes.errorText
                  }
                >
                  {strings.join_us.messages.one_letter}.{" "}
                </span>
                <span
                  className={
                    !get(errors, "password.touched", false)
                      ? classes.password_validation
                      : get(errors, "password.numOrSymbol", false)
                      ? classes.successText
                      : classes.errorText
                  }
                >
                  {strings.join_us.messages.number_symbol}
                </span>
              </div>
              <div>
                <CustomTextField
                  clearIcon
                  onChange={onInputBlur}
                  name={"phonenumber"}
                  label={strings.join_us.titles.phone_number}
                  value={
                    get(phonenumber, "length", 0) === 10
                      ? formatPhoneNumber(phonenumber)
                      : phonenumber
                  }
                  onBlur={(e) => onInputBlur(e, true)}
                  inputProps={{
                    maxLength: countryCode === "USA" ? 10 : 20,
                    onFocus: onPhoneNumberFocus,
                  }}
                  style={{ marginLeft: "23px" }}
                  // className={`${classes.inp_initial} ${classes.textFieldSuccess}`}
                  className={`${
                    get(errors, "phonenumber.error", false)
                      ? classes.inp_error
                      : classes.inp_success
                  } ${classes.textFieldSuccess}`}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        tabIndex="-1"
                        aria-label="toggle password visibility"
                        className={classes.password_icon}
                      >
                        <div className={classes.phoneNumberActions}>
                          <Close_Icon
                            className={classes.phonenum_clr}
                            onMouseUp={onClearInput("phonenumber")}
                          />
                          <img
                            src={
                              countryCode === "USA"
                                ? "/assets/images/USA.PNG"
                                : "/assets/images/nonUSA.PNG"
                            }
                            className={classes.countryImage}
                          />
                          <Chevron_Down_Icon
                            className={classes.dropDownIcon}
                            onClick={triggerCountryDropdown}
                          />
                          {showCountryDropdown && (
                            <div className={classes.dropdownOptions}>
                              <div
                                onClick={() => {
                                  handleCountryChange("USA");
                                }}
                              >
                                USA
                              </div>
                              <div
                                onClick={() => {
                                  handleCountryChange("nonUSA");
                                }}
                              >
                                Non-USA
                              </div>
                            </div>
                          )}
                        </div>
                      </InputAdornment>
                    ),
                  }}
                  error={false}
                />
                {!get(errors, "phonenumber.error", false) ? (
                  <div className={classes.phone_suggestion}>
                    {strings.join_us.messages.option_login}
                  </div>
                ) : (
                  <div className={classes.error_txt}>
                    <img
                      src="assets/icons/info_1.svg"
                      className={classes.info}
                    />{" "}
                    {get(errors, "phonenumber.message")
                      ? get(errors, "phonenumber.message")
                      : "Delete Phone Number or Enter 10 digits"}
                  </div>
                )}
              </div>

              <Grid container item justify="center" alignItems="center">
                {/* <Breadcrumbs separator=" " className={classes.breadcrumb}> */}
                <Grid container item>
                  <label className={classes.breadcrumbText}>
                    {strings.join_us.titles.you_are_a}
                  </label>
                </Grid>
                {/* <CustomLink
                    underline={userType === "Company" ? "always" : "none"}
                    onClick={handleUserTypeChange("Company")}
                  >
                    <label className={classes.userTypes}>
                      {strings.join_us.titles.company}
                    </label>
                  </CustomLink>
                  <CustomLink
                    underline={userType === "Individual" ? "always" : "none"}
                    value="Company"
                    onClick={handleUserTypeChange("Individual")}
                  >
                    <label className={classes.userTypes}>
                      {strings.join_us.titles.individual}
                    </label>
                  </CustomLink> */}
                <CustomButton
                  variant="outlined"
                  color="primary"
                  size="large"
                  custom_type="button"
                  disabled={disableSubmit}
                  onMouseUp={() => {
                    submitForm("Company");
                  }}
                  className={classes.user_type_button}
                >
                  {strings.join_us.titles.company}
                </CustomButton>
                <CustomButton
                  variant="outlined"
                  color="primary"
                  size="large"
                  disabled={disableSubmit}
                  onMouseUp={() => {
                    submitForm("Individual");
                  }}
                  custom_type="button"
                  className={classes.user_type_button}
                >
                  {strings.join_us.titles.individual}
                </CustomButton>
                {/* </Breadcrumbs> */}
              </Grid>
              {/* <Grid container item justify="center" alignItems="center">
                <CustomButton
                  variant="outlined"
                  color="primary"
                  size="large"
                  custom_type="submit"
                  className={classes.join_us_button}
                  disabled={disableSubmit}
                  onClick={submitForm}
                  endIcon={
                    !disableSubmit && (
                      <Ribbon
                        color="primary"
                        style={{
                          fontSize: "47px",
                          marginTop: "-10px",
                          marginRight: "-5px",
                        }}
                      />
                    )
                  }
                >
                  {strings.join_us.titles.join}
                </CustomButton>
              </Grid> */}
              <Grid
                container
                item
                justify="center"
                alignItems="center"
                className={classes.account_exists}
              >
                {strings.join_us.titles.account_exists} &nbsp;
                <CustomLink
                  underline="always"
                  className={classes.cursor}
                  onClick={openLoginModal}
                >
                  <label className={classes.cursor}>
                    {strings.join_us.titles.sign_in}
                  </label>
                </CustomLink>
              </Grid>
              {/* <Grid
                container
                item
                justify="center"
                alignItems="center"
                className={classes.terms_services}
              >
                {strings.join_us.titles.sign_up_fr} &nbsp;
                <CustomLink underline="always">
                  <label>{strings.join_us.titles.terms_services}</label>
                </CustomLink>
              </Grid> */}
            </div>
          </form>
          <div className={classes.modal_root}>
            <Modal
              open={showTinyPopover}
              aria-labelledby="modal-title"
              disablePortal
              disableEnforceFocus
              disableAutoFocus
              disableScrollLock={true}
              hideBackdrop={true}
              aria-describedby="modal-description"
              className={classes.modal_root}
            >
              <div className={classes.modal}>
                {/* <Close_Icon
                  className={classes.cross_icon}
                  onClick={closePopoverModal}
                /> */}
                <p className={classes.modal_title} id="modal-title">
                  {strings.join_us.titles.profile_name}:
                </p>
                <p className={classes.modal_description} id="modal-description">
                  {strings.join_us.titles.popover_description}
                </p>
              </div>
            </Modal>
          </div>
        </Grid>
      </CustomModal>
    </>
  );
}
export default styleSheet(Joinus);
