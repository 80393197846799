import _ from "lodash";
import { JoinusApi } from "../api";
import { LoginTypes } from "../actionTypes";
import { ApiActions } from ".";

export default {
  joinUs: (postObj, cb) => {
    return async (dispatch) => {
      ApiActions.request(dispatch);
      let res = null;
      try {
        res = await JoinusApi.joinUs(postObj);
        dispatch({
          type: LoginTypes.JOIN_US,
          data: res,
        });
        cb && cb(_.get(res, "data", {}));
        ApiActions.success(dispatch);
      } catch (err) {
        dispatch({
          type: LoginTypes.JOIN_US,
          data: err.response.data,
        });
        cb && cb(_.get(err.response, "data", {}));
        ApiActions.failure(dispatch, err);
      }
    };
  },
  sendThanksEmail: (data, cb) => {
    return async (dispatch) => {
      ApiActions.request(dispatch);
      try {
        const res = await JoinusApi.sendThanksEmailNotification(data);
        cb && cb(res);
        ApiActions.success(dispatch);
      } catch (err) {
        ApiActions.failure(dispatch, err);
      }
    };
  },
  closeSuccessModal: () => {
    return {
      type: "CLOSE_MODAL",
    };
  },
  clearJoinUs: () => {
    return {
      type: "RESET_JOIN_US",
    };
  },
  setErrorMsg: (err) => ({ type: LoginTypes.RESET_ERR_MSG, err }),
};
