import React, { useEffect } from "react";
import { get } from "lodash";

import Loading from "../../../components/common/loading";

function AppRedirect(props) {
  const returnHostWithEnv = (usertype, env) => {
    switch (usertype) {
      case "lpack":
        return `tradeworks-${env}-fe.azurewebsites.net`;
      case "cpack":
        return `tradeworks-cpack-${env}-fe.azurewebsites.net`;
      case "superadmin":
        return `tradeworks-superadmin-${env}-fe.azurewebsites.net`;
      default:
        break;
    }
  };
  const returnHost = (usertype) => {
    switch (usertype) {
      case "lpack":
        return "localhost:3001";
      case "cpack":
        return "localhost:3002";
      case "superadmin":
        return "localhost:4000";
      default:
        break;
    }
  };
  useEffect(() => {
    let host = get(window, "location.host", "");
    const usertype = get(props, "match.params.usertype", "");
    if (host) {
      if (host.includes("localhost")) {
        host = returnHost(usertype);
        window.location.href = `http://${host}${props.location.pathname}`;
      } else {
        if (host.includes("-dev-")) {
          host = returnHostWithEnv(usertype, "dev");
        }
        if (host.includes("-qa-")) {
          host = host = returnHostWithEnv(usertype, "qa");
        }
        if (host.includes("-stage-")) {
          host = host = host = returnHostWithEnv(usertype, "stage");
        } else {
          let hostUrl = host?.includes("beta")
            ? "https://beta.thetrade.works/"
            : host?.includes("twwstage")
            ? "http://twwstage.franklinreport.com/"
            : "https://trade-works.com/";
          host = usertype === "lpack" ? `${hostUrl}lpack` : `${hostUrl}cpack`;
        }
        window.location.href = `${host}${props.location.pathname}`;
      }
    }
  }, []);
  return <Loading loading={true} />;
}
export default AppRedirect;
