import React from "react";

import { color } from "../../utilities/themes";
import newPasswordStyles from "./styles/newPasswordStyles";
import Text from "../../components/common/ui_kit/text";
import Row from "../../components/common/ui_kit/row";
import strings from "../../utilities/strings";
import CustomButton from "../../components/navigations/custom_buttons";
import CustomLink from "../../components/navigations/custom_links";
import CustomModal from "../../components/inputs/custom_modal";

function RecoverHere(props) {
  const { classes, showRecoverHere, toggleModal } = props;

  const redirectTosignIn = () => {
    toggleModal("showRecoverHere", "displayLogin");
  };

  return (
    <>
      <CustomModal
        open={showRecoverHere}
        aria-labelledby="modal-title"
        disablePortal
        aria-describedby="modal-description"
        disableAutoFocus
      >
        <div className={classes.recover_here_width}>
          <div className={`${classes.textRight} ${classes.margin_top_20}`}>
          </div>
          <div className={classes.textCenter}>
            <img
              src="/assets/images/telephone_image.png"
              className={classes.recover_img}
            />
          </div>
          <div className={`${classes.spacing_padding} ${classes.margin_top_20}`}>
            <div className={classes.textCenter}>
              <Text
                size={18}
                color={color.primary_palette.black}
                family="gillsans_sb"
              >
                {strings.passwords.titles.recover_here_txt} <br/>
              </Text>
              <a style={{color:`${color.primary_palette.highlight_purple}`, fontSize:'18px'}} href="mailto:office@franklin.works">office@franklin.works</a>
            </div>
            <Row className={classes.footer_recover_spacing}>
              <div className={classes.sign_in}>
                <CustomButton
                  className={classes.locateBtn}
                  onClick={redirectTosignIn}
                >
                  {strings.passwords.titles.got_it}
                </CustomButton>
              </div>
            </Row>
          </div>
        </div>
      </CustomModal>
    </>
  );
}

export default newPasswordStyles(RecoverHere);
