import React, { useState } from "react";
import { get } from "lodash";

import { color } from "../../utilities/themes";
import newPasswordStyles from "./styles/newPasswordStyles";
import Text from "../../components/common/ui_kit/text";
import Row from "../../components/common/ui_kit/row";
import CustomTextField from "../../components/inputs/custom_textfields";
import strings from "../../utilities/strings";
import CustomButton from "../../components/navigations/custom_buttons";
import CustomLink from "../../components/navigations/custom_links";
import Close_Icon from "../../components/data_display/icons/Close";
import CustomRadioButton from "../../components/inputs/custom_radio_button";
import CustomModal from "../../components/inputs/custom_modal";

function ResetPassword(props) {
  const { classes, showResetPassword, toggleModal } = props;
  const [values, setValues] = useState({
    recoveryMethod: "",
    email: "linksalas@tradeworks.com",
    phone: "9490039883",
  });

  const transformEntry = (item, type) => {
    const { email } = values;
    switch (type) {
      case "email":
        var parts = item.split("@"),
          len = parts[0].length;
        return email.replace(parts[0].slice(1, -1), "•".repeat(len - 2));
      case "phone":
        return item[0] + "*".repeat(item.length - 4) + item.slice(-3);
      default:
        return "";
    }
  };

  const redirectTosignIn = () => {
    toggleModal("showResetPassword", "displayLogin");
  };

  const redirectToJoinUs = () => {
    toggleModal("showResetPassword", "displayJoinUs");
  };

  const setRecoveryMethod = (e) => {
    const { name } = e.target;
    setValues({ ...values, recoveryMethod: name });
  };

  const handleGeneratePassword = () => {
    const { recoveryMethod } = values;
    if (recoveryMethod === "email") {
      return null;
    }
    if (recoveryMethod === "phone") {
      toggleModal("showResetPassword", "showOtpModal");
    }
  };
  return (
    <>
      <CustomModal
        open={showResetPassword}
        aria-labelledby="modal-title"
        disablePortal
        aria-describedby="modal-description"
        disableAutoFocus
      >
        <div className={classes.recover_password_width}>
          <div className={classes.textRight}>
            <CustomButton
              className={classes.crossBtn}
              onClick={() => {
                toggleModal("showResetPassword");
              }}
            >
              <Close_Icon />
            </CustomButton>
          </div>
          <div className={classes.textCenter}>
            <img
              src="/assets/images/recover1.png"
              className={classes.margin_top_25}
            />
          </div>
          <div className={classes.spacing_padding}>
            <Text
              size={24}
              color={color.primary_palette.black}
              family="gillsans_sb"
              transform="uppercase"
              className={classes.textCenter}
            >
              {strings.passwords.titles.reset_password}
            </Text>
            <from>
              <div className={classes.textCenter}>
                <CustomTextField
                  className={classes.phn_email_blk}
                  label={strings.passwords.titles.email_or_phone}
                  name="email"
                />
              </div>
              <div className={classes.spacingRadio}>
                <Text
                  size={10}
                  color={color.primary_palette.franklin_purple}
                  family="gillsans_sb"
                  className={classes.textCenter}
                >
                  {strings.passwords.titles.set_new_password_via}
                </Text>
                <div>
                  <CustomRadioButton
                    className={classes.radioBtn}
                    name="email"
                    label={strings.login.titles.email}
                    checked={get(values, "recoveryMethod", "") === "email"}
                    onChange={setRecoveryMethod}
                  />
                  <Text
                    size={10}
                    color={color.primary_palette.franklin_purple}
                    className={classes.inline_block}
                  >
                    {transformEntry(get(values, "email", ""), "email")}
                  </Text>
                </div>
                {/* <div>
                  <CustomRadioButton
                    className={classes.radioBtn}
                    label={strings.login.titles.email}
                  />
                  <Text
                    size={10}
                    color={color.primary_palette.franklin_purple}
                    className={classes.inline_block}
                  >
                    l••••••s@yahoo.com
                  </Text>
                </div> */}
                <div>
                  <CustomRadioButton
                    className={classes.radioBtn}
                    label={strings.passwords.titles.test}
                    name="phone"
                    checked={get(values, "recoveryMethod", "") === "phone"}
                    onChange={setRecoveryMethod}
                  />
                  <Text
                    size={10}
                    color={color.primary_palette.franklin_purple}
                    className={classes.inline_block}
                  >
                    {transformEntry(get(values, "phone", ""), "phone")}
                  </Text>
                </div>
              </div>
              <div className={classes.textCenter}>
                <CustomButton
                  className={classes.locateBtn}
                  onClick={handleGeneratePassword}
                >
                  {strings.passwords.titles.get_new_password}
                </CustomButton>
              </div>
            </from>
            <Row className={classes.footer_spacing}>
              <div className={classes.sign_in}>
                <Text
                  size={14}
                  color={color.primary_palette.franklin_purple}
                  family="gillsans_sb"
                >
                  {strings.passwords.titles.Wait_password}
                </Text>
                <CustomLink
                  underline="always"
                  className={classes.recover}
                  onClick={redirectTosignIn}
                >
                  <label className={classes.cursor}>
                    {strings.passwords.titles.sign_in}
                  </label>
                </CustomLink>
              </div>
              <div className={classes.join_here}>
                <Text
                  size={14}
                  color={color.primary_palette.franklin_purple}
                  family="gillsans_sb"
                >
                  {strings.passwords.titles.not_member}
                </Text>
                <CustomLink
                  underline="always"
                  className={classes.recover}
                  onClick={redirectToJoinUs}
                >
                  <label className={classes.cursor}>
                    {strings.passwords.titles.join_here}
                  </label>
                </CustomLink>
              </div>
            </Row>
          </div>
        </div>
      </CustomModal>
    </>
  );
}

export default newPasswordStyles(ResetPassword);
